import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const loginstatus = localStorage.getItem("login_status");

  if (loginstatus !== "true") {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
