import React, { useState, useEffect, useRef } from "react";
// import { setRelatedDetails } from "../../Redux/Slice/showSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { FaAngleDoubleRight, FaPlay } from "react-icons/fa";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import LiveVideoPlayer from "../Videoplayer/LiveVideoPlayer";
import { SlArrowDown } from "react-icons/sl";
import {
  BASE_URL,
  acceptedMediaTypes,
  access_token,
  auth_token,
} from "../../services/config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import ShowVideoPlayer from "../Videoplayer/showvideoplayer";
import Slider from "react-slick";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
import { setIsrelatedVideosClicked, setVideoDetails } from "../../Redux/Slice/videodetailSlice";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { setSeasondropdownData } from "../../Redux/Slice/seasonsSlice";
import { setRelatedDetails } from "../../Redux/Slice/showSlice";
// import GPTAdTwo from "./gptaddtwo";
// import { Gpt } from 'react-gpt-ads';
import { guestuserid } from "../../App";
import GPTAdThree from "./gptaddthree";
import { setMainTraynamedata } from "../../Redux/Slice/navlinkcallSlice";
import { setThroughSeach } from "../../Redux/Slice/throughSearchSlice";


const HLSPlayerWithQualityControl = () => {
  const verticaldefultimage = "/images/horizontaldefault.jpg";
  const horizontaldefultimage = "/images/verticaldefault.jpg";
  const etv_exclusive_banner_image = "/images/etv-exclusive_banner.png";
  const tv_shows_banner_image = "/images/tv_shows_banner.png";
  const top10_image = "/images/top10default.jpg";
  const twoGridDefault = "/images/twogridimage.jpg";
  const singleGridDefault = "/images/singlegriddefault.jpg";

  const sourcetab = localStorage.getItem("selectedMenuItem");

  const {
    category,
    main,
    subcategory,
    seo_url,
    seo_url_friendly,
    fifth_url,
  } = useParams();

  const [relatedData, setRelatedData] = useState({})

  const regionsData = useSelector((state) => state.region.regionsData);


  useEffect(() => {
    const isMobile = window.innerWidth <= 768; 

    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, []);
  // console.log("category", category);
  // console.log("subcategory", subcategory);
  // console.log("main", main);
  // console.log("seo_url", seo_url);
  // console.log("seo_url_friendly", seo_url_friendly);
  // console.log("fifth_url", fifth_url);
  const effectRan = useRef(false)
  function getPageName() {
    const path = window.location.pathname;
    const parts = path.split('/'); // Split path by '/'
    return parts[1] ?? "home"; // Return the second part after localhost/
  }
  const param1Value = getPageName();
  const getLoginStatus = localStorage.getItem("login_status")
  const getUserId = localStorage.getItem("user_id");
  const navigate = useNavigate();
  let isSliding = false;
  const location = useLocation();
  const adSlotRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedContent, setSelectedContent] = useState("");
  const [selectedCatlog, setSelectedCatlog] = useState("");
  const [relatedVideoData, setRelatedVideoData] = useState({});
  const [selectedTab, setSelectedTab] = useState("relatedinfo");
  const [promotypedetails, setPromotypedetails] = useState({});
  const [miniclipsdetails, setMiniclipsdetails] = useState({});
  const [behindthescenesdetails, setBehindthescenesdetails] = useState({});
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [detailsData, setDetailsData] = useState({});
  const [episodetypedetails, setEpisodetypedetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [movieVideoListsData, setMovieVideoListsData] = useState(null);
  const [medialistsdata, setMedialistsdata] = useState([]);
  const [mediaListsDataLoading, setMediaListsDataLoading] = useState(false)
 // const [isSubcategory_flag, setIsSubcategory_flag] = useState(false)
 const [isdropdownContentSelected, setIsdropdownContentSelected] = useState(false)
 const [selectedValue, setSelectedValue] = useState('');

  const [seeAllObject, setSeeAllObject] = useState(null);
  const [landingSeeAllObject, setLandingSeeAllObject] = useState(null);

  const [subCat, setSubCat] = useState([]);
  const [movieVideoListsCategories, setMovieVideoListsCategories] =
    useState(null);
  const [showPerformance, setShowPerformance] = useState(false);
  const performanceMenuRef = useRef(null);
  const [selectedlng, setSelectedlng] = useState("eng");
  const [isRequiredRelated, setIsRequiredRelated] = useState(0);
  const [imageLoadedArrayMedialist, setImageLoadedArrayMedialist] = useState(new Array(0).fill(false));
  const handleImageLoadMedialist = (index) => {
    setImageLoadedArrayMedialist(prevState => {
      const newState = [...prevState];
      newState[index] = true; // Set the value at the specified index to true
      return newState; // Return the updated state
    });
  };
  // const showPerformanceDropdown = () => {
  //   setIsdropdownContentSelected(true)
  //   setShowPerformance(!showPerformance);
  // };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        performanceMenuRef.current &&
        !performanceMenuRef.current.contains(event.target)
      ) {
        setShowPerformance(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleMore = (link, data, identifyunic) => {
    localStorage.setItem("more_parent_id", data.parent_id);
    localStorage.setItem("more_category", data.category);

    if (identifyunic == "all") {
      let urlmore = "/more" + link;
      navigate(urlmore);
    }
  };
  const handleRelatedMore = (link, data, identifyunic,relatedmovietitle) => {
    localStorage.setItem("relatedmovietitle", relatedmovietitle);
    localStorage.setItem("event_seo_url", link);
    localStorage.setItem("event_seo_url_friendly", seo_url);
    localStorage.setItem("event_identifyunic", "related");
    localStorage.setItem("event_genres", detailsData.display_genres);
    if(category === "home"){ 
      localStorage.setItem("event_first", seo_url);
      localStorage.setItem("event_secund", main);
      localStorage.setItem("event_three", seo_url_friendly);
      localStorage.setItem("event_four", fifth_url);
    }else{ 
      localStorage.setItem("event_first", category);
      localStorage.setItem("event_secund", seo_url);
      localStorage.setItem("event_three", main);
      localStorage.setItem("event_four", seo_url_friendly);
    }

    if (identifyunic === "movies") {
      let urlmore = "/" + link + "/related-" + link + "/all";
      navigate(urlmore);
    }
  };
  const seeAllItemsVideo = (carouselItems, title, catalog) => {
    localStorage.setItem("event_seo_url_friendly", main || seo_url_friendly  );
    localStorage.setItem("event_seo_url", seo_url);
    localStorage.setItem("event_particularvideo_url",seo_url_friendly);
    localStorage.setItem("event_identifyunic", catalog);
    localStorage.setItem("event_title", title);

    if(category === "home"){ 
      localStorage.setItem("event_first", seo_url);
      localStorage.setItem("event_secund", main);
      localStorage.setItem("event_three", seo_url_friendly);
      localStorage.setItem("event_four", fifth_url);
    }else{ 
      localStorage.setItem("event_first", category);
      localStorage.setItem("event_secund", seo_url);
      localStorage.setItem("event_three", main);
      localStorage.setItem("event_four", seo_url_friendly);
    }
    const newPath = `/${catalog}/${title.split(" ").join("-")}/all`;
    navigate(newPath);
  };
  const seeAllItems = (carouselItems, title, catalog) => {
    setLandingSeeAllObject({ ...carouselItems, seeAllPageTitle: title });

    setSeeAllObject({ ...carouselItems, seeAllPageTitle: title });
    if (catalog === "shows" || catalog === "serials") {

      localStorage.setItem("event_seo_url_friendly", carouselItems.friendly_id);
    } else {
      localStorage.setItem("event_seo_url_friendly", seo_url_friendly);
    }

    localStorage.setItem("event_seo_url", seo_url);
    localStorage.setItem("event_identifyunic", catalog);
    localStorage.setItem("event_title", title);

    // localStorage.setItem("eventsitem_token", JSON.stringify({ ...carouselItems, seeAllPageTitle: title }));
    const newPath = `/${catalog}/${title.split(" ").join("-")}/all`;
    navigate(newPath);
  };
  const getDetailsUrl = () => {
    if (category === "telugu-movies") {
      if (category !== "" && seo_url !== "" && seo_url_friendly !== "") {
        return `${BASE_URL}/catalogs/${category}/items/${seo_url}/videolists/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
      } else {
        return `${BASE_URL}/catalogs/${category}/items/${seo_url}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
      }
    } else if (category === "shows") {
      if (category && subcategory) {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      } else {
        if (main === "seasons") {
          return `${BASE_URL}catalogs/${category}/items/${seo_url}/${main}/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
        } else {
          return `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
        }
      }
    } else if (category === "events") {
      if (category && subcategory) {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      } else {
        if (main === "seasons") {
          return `${BASE_URL}catalogs/${category}/items/${seo_url}/${main}/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
        } else {
          return `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
        }
      }
    } else if (category === "live") {
      return `${BASE_URL}catalogs/${category}/items/${seo_url}/related?auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;
    } else if (category === "win-exclusive") {
      if (category !== "" && seo_url !== "" && seo_url_friendly !== "") {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}/episodes/${seo_url_friendly}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
      } else {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      }
    } else if (category === "original-movies") {
      return `${BASE_URL}catalogs/telugu-movies/items/${seo_url}?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    } else if (category === "serials") {
      return `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}/episodes/${seo_url_friendly}?auth_token=${auth_token}&access_token=Ay6KCkajdBzztJ4bptpW&item_language=eng&region=${regionsData?.country_code2}`;
    }
    // else if (category === "serials") {

    //   if (category && subcategory) {
    //     return `${BASE_URL}catalogs/${category}/items/${subcategory}/subcategories/${main}/episodes/${seo_url}?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
    //   } else {
    //     return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    //   }
    // }
    else if (category === "news") {
      if (category !== "" && seo_url !== "" && seo_url_friendly === "") {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      } else if (category !== "" && seo_url !== "" && seo_url_friendly !== "") {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes/${seo_url_friendly}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      }
    } else if (main === "news") {
       

      return `${BASE_URL}catalogs/${main}/items/${seo_url_friendly}/episodes/${fifth_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
   
   
    } else if (category === "recipes" && seo_url_friendly !== "") {
      return `${BASE_URL}catalogs/${category}/items/${seo_url_friendly}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    } else if (category === "annadata-all") {
      return `${BASE_URL}catalogs/${seo_url}/items/${seo_url_friendly}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    } else if (category === "health") {
      return `${BASE_URL}catalogs/${main}/items/${fifth_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    } else if (seo_url === "channels") {
      return `${BASE_URL}catalogs/${main}/items/${fifth_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    } else if (category === "channels") {
      return `${BASE_URL}catalogs/${seo_url}/items/${seo_url_friendly}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
    } else {
      if (category !== "" && seo_url !== "" && seo_url_friendly === "") {
        return `${BASE_URL}catalogs/${category}/items/${seo_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      } else if (category !== "" && seo_url !== "" && seo_url_friendly !== "") {
        return `${BASE_URL}catalogs/${category}/items/${seo_url_friendly}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      } else if (
        category !== "" &&
        seo_url !== "" &&
        main !== "" &&
        fifth_url !== ""
      ) {
        return `${BASE_URL}catalogs/${main}/items/${fifth_url}.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      }
    }
  };

  const commonSettings = (data) => ({
    dots: false,
    infinite: false,
    speed: 500,
    lazyload: "anticipated",
    autoplay: false,
    centermode: false,
    delay: 3000,
    slidestoshow: 6.5,
    slidestoscroll: 3,
    initialSlide: 0,
    nextarrow: <SampleNextArrow />,
    prevarrow: <SamplePrevArrow />,
  
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidestoshow: 3.5,
          slidestoscroll: 3,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidestoshow: 2,
          slidestoscroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidestoshow: getSlidesToShow(data),
          slidestoscroll: 2,
          initialSlide: 2,
          dots: false,
          infinite: false,
        },
      },
    ],
  });




function getSlidesToShow(data) {
  return data && data.total_items_count > 2 ? 2 : '';
}

  let getRelatedShows = async (genres,media_type,isSubcategory_flag) => {
    setLoading(true);
    //const genresval = detailsData.genres?.join(",");
    // else if(category === "recipes"){
    //   seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
    // }
    let seourls = "";
    if(category === "recipes"){
      seourls = `${BASE_URL}catalogs/${category}/items/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
      console.log("1");
    }else if(seo_url === "etv-life"){
      seourls = `${BASE_URL}catalogs/${seo_url}/items/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
      console.log("2");
    }else if(category !== "" && seo_url !== "" && seo_url_friendly !== "" && category === "serials"){
      seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}/episodes/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
      console.log("3");
    }else if(category !== "" && seo_url !== "" && seo_url_friendly !== "" && category === "events"){
      seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
      console.log("4");
    }else if(category !== "" && seo_url !== "" && seo_url_friendly !== "" && main === "seasons" && category=== "shows"){

      seourls = `${BASE_URL}catalogs/${category}/items/${main}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}`;
      console.log("5");
    }else if(category !== "" && seo_url !== "" && seo_url_friendly !== "" && main !== "seasons" && category=== "shows"){
      seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}/episodes/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
      console.log("6");
    }else if(category !== "" && seo_url !== "" && seo_url_friendly !== "" && fifth_url!=="" && category === "home" && seo_url=== "channels"){

      seourls = `${BASE_URL}catalogs/${main}/items/${main}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}`;
      console.log("7");
    }else{
      if(category !== undefined && seo_url !== undefined  && main !== undefined && seo_url_friendly !== undefined && fifth_url!== undefined && main === "news"){
          seourls = `${BASE_URL}catalogs/${main}/items/${seo_url_friendly}/episodes/${fifth_url}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
          console.log("8");
      }else if(category !== undefined && seo_url !== undefined  && main === undefined && seo_url_friendly !== undefined && category === "news"){
        // console.log("category",category);
        // console.log("seo_url",seo_url);
        // console.log("seo_url_friendly",seo_url_friendly);
        seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/episodes/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;
        // console.log("9");
      }else if(category !== "" && seo_url !== ""  && main !== "" && seo_url_friendly !== ""  ){
        if(isSubcategory_flag === true){
          seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}/episodes/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}&episode_type=${media_type}`;

        }else{
          seourls = `${BASE_URL}catalogs/${category}/items/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}`;

        }
        // console.log("isSubcategory_flag", isSubcategory_flag)
      //  console.log("10");
      }else if(category !== "" && seo_url !== ""  && main !== "" && category !== "news"){
        seourls = `${BASE_URL}catalogs/${category}/items/${main}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genres}`;
        // console.log("11");
      }

    }

    try{
      const response =  await axios.get(seourls);
      if (response.status === 200 && response.data.data && response?.data?.data?.items?.length > 0) {
        setIsRequiredRelated(1);
        // setRelatedData(response?.data?.data)
        dispatch(setRelatedDetails({relatedDetails: response?.data?.data}))
        localStorage.setItem("relatedtitle", JSON.stringify(response?.data?.data?.title))
      }else {}
    }catch (error) {
      console.error("Error fetching related details:", error);
    } finally {
      setLoading(false);
    }
    };

  const getRelatedVideoLists = async (genres,media_type) => {
    setLoading(true);
    let seourls = "";
    if (category !== "" && seo_url !== "" && seo_url_friendly !== "") {
      seourls = `${BASE_URL}catalogs/${category}/items/${seo_url}/videolists/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}`;
      // https://stagingott.etvwin.com:3005/catalogs/serials/items/ravoyi-chandamama/videolists/epi-754-6/related.gzip?auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&item_language=eng&region=IN
      // https://stagingott.etvwin.com:3005/catalogs/serials/items/ravoyi-chandamama/episodes/epi-754-6/related.gzip?auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&item_language=eng&region=IN
    } 
    try{
      const response =  await axios.get(seourls);
      if (response.status === 200 && response.data.data && response?.data?.data?.items?.length > 0) {
        setIsRequiredRelated(1);
        setRelatedVideoData(response.data.data);
      }else{}
    }catch (error) {
      console.error("Error fetching related details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchVideoLists = async (contentid) => {
    try {
      const movieVideoListsURL = `${BASE_URL}/catalog_lists/movie-videolists/?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&parent_id=${contentid}`;
      const response = await axios.get(movieVideoListsURL);
      if (response.status === 200) {
        setMovieVideoListsData(response.data.data);
        if(response.data.data.catalog_list_items.length > 0){
          setIsRequiredRelated(1);
        }
        setMovieVideoListsCategories(response.data.data.catalog_list_items);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchMediaLists = async (display_title, friendly_id, selectedlng) => {
    try {
      const fetchmediaurl = `${BASE_URL}/catalog_lists/${friendly_id}.gzip?item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      const response = await axios.get(fetchmediaurl);
      if (response.status === 200) {
        if (response.data.data) {
         
          const catalogListItems = response.data.data.catalog_list_items; // Assuming this is an array or an array-like object
           dispatch(setSeasondropdownData({ seasondropdownData: catalogListItems }));
          if (Array.isArray(catalogListItems)) {
            if(catalogListItems.length > 0){
              setIsRequiredRelated(1);
            }
            const newMediaListsData = [];
            catalogListItems.forEach((element) => {
              const isDuplicate = newMediaListsData.some(
                (item) => item.display_title === element.display_title
              );

              if (!isDuplicate) {
                newMediaListsData.push({
                  display_title: element.display_title,
                  Item: element.catalog_list_items,
                  seo_url: element.seo_url,
                  total_items_count: element.total_items_count,
                });
              } else {
                console.log("Element is a duplicate, skipping..."); // Log when skipping a duplicate element
              }
            });
            setMedialistsdata(newMediaListsData);
          } else {
            console.error("catalogListItems is not an array.");
          }
        } else {
          console.error("No data found in response.data.data.");
          setMediaListsDataLoading(false)
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchDetailsPage = async () => {
    setLoading(true);
    await axios
      .get(getDetailsUrl())
      .then((response) => {
        if (response.status === 200) {
          setDetailsData(response?.data?.data);
          dispatch(
            setVideoDetails({
              videodetailsitems: response.data.data,
            })
          );
          setSubCat(response?.data?.data?.subcategories || []);
          const friendlyId = response?.data?.data?.friendly_id;
          const lastEpisode = response?.data?.data?.last_episode;
          if (lastEpisode) {
            setSelectedContent(lastEpisode.content_id);
            setSelectedCatlog(lastEpisode.catalog_id);
          } else {
            setSelectedContent(response?.data?.data?.content_id);
            setSelectedCatlog(response?.data?.data?.catalog_id);
          }
          // fetchVideoLists(selectedContent);
          // var genres = response?.data?.data?.genres;
          // var media_type = response?.data?.data?.media_type;
          // getRelatedShows(genres, media_type, response?.data?.data?.subcategory_flag);
          // getRelatedVideoLists(genres, media_type);
          // response?.data?.data?.episodetype_tags?.forEach(
          //   (subitem, subindex) => {
          //     EpisoderelatedVideosData(subitem?.display_title, subitem?.name, selectedlng, friendlyId);
          //     if (subitem?.item_type === "list-2d") {
          //       setMediaListsDataLoading(true);
          //       fetchMediaLists(
          //         subitem.display_title,
          //         subitem.media_list,
          //         selectedlng
          //       );
          //     }
          //   }
          // );


          
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        navigate("/");
      });
  };
  
  function formatDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  }

  function formatReleaseDate(dateString) {
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
  }
  
  const EpisoderelatedVideosData = async (display_title, episode_type, selectedlng, subcatUrl) => {
    try {
      const episodetypeepisode = `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${subcatUrl}/episodes.gzip?item_language=${selectedlng}&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&episode_type=${episode_type}`;
  
      const response = await axios.get(episodetypeepisode);
  
      if (response.status === 200) {
        if (response?.data?.data?.total_items_count > 0) {
          setIsRequiredRelated(1);
        }
        switch (episode_type) {
          case "promo":
            setPromotypedetails(response?.data?.data);
            break;
          case "mini_clips":
            setMiniclipsdetails(response?.data?.data);
            break;
          case "behind_the_scenes":
            setBehindthescenesdetails(response?.data?.data);
            break;
          case "episode":
          setEpisodetypedetails(response?.data?.data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const genresval = detailsData.genres?.join(",");
  const mediatypeval = detailsData.media_type;
  const getRelatedVideosUrl = () => {
    if (category === "recipes" || category === "channels" ) {
      return `${BASE_URL}catalogs/${seo_url}/items/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genresval}&episode_type=${mediatypeval}`;
    } else if (category === "news") {
      return `${BASE_URL}catalogs/${main}/items/${seo_url_friendly}/episodes/${fifth_url}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genresval}&episode_type=${mediatypeval}}`;
    } else if (category === "health") {
      return `${BASE_URL}catalogs/${main}/items/${fifth_url}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genresval}&episode_type=${mediatypeval}`;
    } else if (category === "shows") {
      return `${BASE_URL}catalogs/${category}/items/${seo_url}/subcategories/${main}/episodes/${seo_url_friendly}/related.gzip?auth_token=${auth_token}&access_token=${access_token}&item_language=eng&region=${regionsData?.country_code2}&genres=${genresval}&episode_type=${mediatypeval}`;
    }else {
      return null;
    }
  };

  const relatedcontent = getRelatedVideosUrl();

  const fetchRelatedVideosData = async (url) => {
    try {
      const response = await axios.get(url);
      if (response.status === 200 && response?.data?.data && response?.data?.data?.total_items_count > 0) {
        setIsRequiredRelated(1);
        setRelatedData(response?.data?.data)
        // dispatch(setRelatedDetails({relatedDetails: response?.data?.data}))
      }else{}
    } catch (error) {
      console.error("Error fetching details:", error);
      if (error.message === "Request failed with status code 422") {
        console.log(error.message);
      } else if (error?.response?.status === 422) {
        console.log(error?.response?.data?.error?.message);
      }
    }
  };

  const {relatedDetails} = useSelector(state => state.shows)
  useEffect(() => {
    const matchedItem = subCat.find(item => item.seo_url === location.pathname);
    if (matchedItem) {
      setSelectedValue(matchedItem.title); 
    }
  }, [location, subCat]);

  useEffect(() => {
    if(effectRan.current === false || selectedContent || selectedCatlog || seo_url_friendly){
      fetchDetailsPage();
      return () => {
        effectRan.current = true;
        setMediaListsDataLoading(false)
        setMedialistsdata([]); 
        setEpisodetypedetails({})
        setPromotypedetails({})
        setMiniclipsdetails({})
        dispatch(setRelatedDetails({relatedDetails: {}}))
      }
    }
  }, [selectedContent, selectedCatlog, seo_url_friendly]);


  useEffect(() => {
    // Set up ad refresh every 10 seconds
    const refreshInterval = setInterval(() => {
      if (window.googletag && adSlotRef.current) {
        window.googletag.pubads().refresh([adSlotRef.current]);
        // console.log('Ad refreshed');
      }
    }, 10000); // Refresh every 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(refreshInterval);
  }, [selectedContent, selectedCatlog, seo_url_friendly, location.pathname]);

  // useEffect(() => {
  //   if (!effectRan.current || selectedCatlog ||seo_url_friendly) {
  //     fetchDetailsPage();
  //     return () => {
  //       effectRan.current = true
  //     }
  //   }
  // }, [seo_url_friendly, selectedCatlog]);


  // useEffect(() => {
  //   setLayer(false); 
  // }, [detailsData])



  // useEffect(() => {
  //   fetchDetailsPage();
  // }, [selectedContent, selectedCatlog, seo_url_friendly]);

  // const { thumbnailposition} = useSelector((state) => state.navmenu_called);

  // console.log("thumbnailpositionsd", thumbnailposition)
  const { videodetailsitems } = useSelector((state) => state.video_details);

  useEffect(() => {
    if (detailsData?.friendly_id && detailsData?.genres) {
      setSubCat(detailsData.subcategories || []);
  
      const lastEpisode = detailsData.last_episode;
      // if (lastEpisode) {
      //   setSelectedContent(lastEpisode.content_id);
      //   setSelectedCatlog(lastEpisode.catalog_id);
      // } else {
      //   setSelectedContent(detailsData?.content_id);
      //   setSelectedCatlog(detailsData?.catalog_id);
      // }
  
      fetchVideoLists(selectedContent);
  
      var genres = detailsData?.genres;
      var media_type = detailsData?.media_type;
      getRelatedShows(genres, media_type, (detailsData?.subcategory_flag || videodetailsitems?.subcategory_flag));
      if(detailsData?.catalog_object?.layout_type === 'movies'){
      getRelatedVideoLists(genres, media_type);
    }


  
      
      if (detailsData?.episodetype_tags?.length > 0) {
        detailsData.episodetype_tags.forEach((subitem) => {
          EpisoderelatedVideosData(subitem?.display_title, subitem?.name, selectedlng, detailsData?.friendly_id);
          if (subitem?.item_type === "list-2d" && subitem.media_list) {
            setMediaListsDataLoading(true);
            fetchMediaLists(subitem.display_title, subitem.media_list, selectedlng);
          }
        });
      }
    }
  }, [selectedContent, selectedCatlog, videodetailsitems?.subcategory_flag]);


  useEffect(() => {
    if (effectRan.current === false) {
      fetchRelatedVideosData(relatedcontent);
      return () => {
        effectRan.current = true
      }
    }
  }, [relatedcontent]);

  const handleGotoPage = async (link, data, index, datamain) => {
    dispatch(setThroughSeach({ throughSeach: false }));
    dispatch(setMainTraynamedata({ maintraynamedata:  datamain}));

    // console.log("ssbbsbsss", index)
    // console.log("datamainsss", datamain)
    // console.log("hshshhshs", data)
    // console.log("linksjjsjs", link)
    setDetailsData(data);
    dispatch(setIsrelatedVideosClicked({ isrelatedVideosClicked: true }))
    setSelectedCatlog(data.catalog_id);
    setSelectedContent(data.content_id);
    const dynamicUrl = link.startsWith("/") ? link : `/${link}`;
    navigate(dynamicUrl);
    setShowThumbnail(true);


    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source: sourcetab?.toLowerCase(),
        video_name: data?.title,
        tray_name: datamain?.display_title?.toLowerCase(),
        platform: "web",
        event_id: 21,
        event_time: new Date().toISOString().slice(0, 19),
        u_id: getLoginStatus === "true" ? getUserId : guestuserid,
        country: regionsData?.country_name,
        content_type: data?.media_type ? data?.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: data?.media_type ? data?.media_type : 'NA',
      });
    }
  };

  useEffect(() => {
    if (isRequiredRelated === 1) {
      setSelectedTab("relatedinfo");

    } else {
      setSelectedTab("moreinfo");

    }
  }, [isRequiredRelated]); // Specify dependencies here

  const [imageLoadedArray, setImageLoadedArray] = useState(new Array(0).fill(false));
  const handleImageLoad = (index) => {
    setImageLoadedArray(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props
    return (
        <div 
            className={className}
            onClick={onClick}
            style={{ cursor: "pointer" }} 
        >
           <GoChevronLeft />
        </div>
    );
}

const SampleNextArrow = (props) => {
  const { className, onClick } = props
  return (
      <div 
          className={className}
          onClick={onClick}
          style={{ cursor: "pointer" }} 
      >
        <GoChevronRight  />
      </div>
  );
}


const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

useEffect(() => {
    const handleResize = () => {
        setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, []);



function generateSlider(data, commonSettings) {
  let defultLocalimage = "";
 
  if (data.layout_type === "etv-exclusive_banner") {
    defultLocalimage = twoGridDefault;
  } else if (data.layout_type === "tv_shows_banner") {
    defultLocalimage = singleGridDefault;
  } else if (data.layout_type === "top10" || data.layout_type === "movie" || data.layout_type === "movies") {
    defultLocalimage = top10_image;
  } else if (data.layout_type === "tv_shows" || data.layout_type === 'shows' || data.layout_type === 'show') {
    defultLocalimage = horizontaldefultimage;
  } else if (
    data.layout_type === "latest_episode" ||
    data.layout_type === "short" ||
    data.layout_type === "episode" ||
    data.layout_type === "mini_movie_poster" ||
    data.layout_type === "movie_poster" ||
    data.layout_type === "videos" ||
    data.layout_type === "promo" ||
    data.layout_type === "mini_clips" ||
    data.layout_type === "recipe" ||
    data.layout_type === "movie_poster" 
  ) {
    defultLocalimage = verticaldefultimage;
  } else{
    defultLocalimage = verticaldefultimage;
  }
  return (
    <Swiper
      {...commonSettings(data)}
      className="slider-one"
      navigation={!isMobile ? true : false}  modules={[Pagination, Navigation]}
      slidesPerView={2}
      spaceBetween={0}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => SampleNextArrow}
      breakpoints={{
        "640": {
          slidesPerView: 3,
          spaceBetween: 11
        },
        "768": {
          slidesPerView: 4,
          spaceBetween: 11
        },
        "1024": {
          slidesPerView: 6.5,
          spaceBetween: 0
        }
      }}
    >
      {data &&
        (data.items || data.Item) &&
        (data.items || data.Item).map((item, index) => {
          const maxCharacters = 24;
          // let displayTitle =
          //   item?.title?.length > maxCharacters
          //     ? item?.title?.substring(0, maxCharacters) + "..."
          //     : item?.title;


              let formattedDate = '';
            if (item.release_date_string) {
              let releaseDate = new Date(item.release_date_string);
              const day = releaseDate.getDate().toString().padStart(2, "0");
              const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
              const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
              formattedDate = `${day} ${monthAbbreviation} ${year}`;
            }
        
            let displayTitle = item.title;
            if (item.release_date_string) {
              displayTitle = item.title.length > 20 ? `${item.title.substring(0, 26)}... ` : `${item.title} (${formattedDate})`;
            } else {
              displayTitle = `${item.title.substring(0, 26)}... `;  // Full title when release_date_string is empty
            }
          return (
            <SwiperSlide key={index}>
            <div
              key={index}
              onClick={(event) => {
                handleGotoPage(item.seo_url, item, index, data);
              }}
            >
              <div className="carousel-car continuewatch">
                <div className="extra lock-icon-class cms-container cw-card">
                  <div className={`thumbnailMovieCard relateddet`}>
<img
  src={
    imageLoadedArray[index]
    ? (data?.web_layout_type || data?.layout_type) === 'tv_shows'
        ? (item?.thumbnails?.medium_3_4?.url || item?.thumbnails?.high_16_9?.url || item?.thumbnails?.high_4_3?.url)
        : (item?.thumbnails?.medium_16_9?.url || item?.thumbnails?.high_4_3?.url)
      : defultLocalimage // Default image before loading
  }
  width="100%"
  alt={displayTitle || "default title"}
  onLoad={() => handleImageLoad(index)}
  onError={(e) => {
    e.target.src = defultLocalimage; // Set default image if loading fails
    handleImageLoad(index); // Mark image as loaded to prevent further attempts
  }}
  loading={!imageLoadedArray[index] ? "eager" : "lazy"}
  className={`img-border-radis ${data?.web_layout_type || data?.layout_type}`}
/>

{imageLoadedArray[index] && (
  <>
    {item?.media_type && acceptedMediaTypes.includes(item.media_type) && (
  <div className="iconcame">
    <FaPlay />
  </div>
)}
  </>
)}



                  </div>

                  {imageLoadedArray[index] && (
  <>
    {item.show_meta_data === 'yes' && item?.media_type && <p className="displayTitle">{displayTitle}</p>}

{!loading &&
  !item?.access_control?.is_free && (
    <div className="lock-postion-class-related"></div>
  )}
  </>
)}
                 
                </div>
              </div>
            </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
}


const relatedlayoutType = relatedVideoData?.web_layout_type ?? relatedVideoData?.layout_type
const episodelayoutType = episodetypedetails?.web_layout_type ?? episodetypedetails?.layout_type
const promolayoutType = promotypedetails?.web_layout_type ?? promotypedetails?.layout_type
const miniclipslayoutType = miniclipsdetails?.web_layout_type ?? miniclipsdetails?.layout_type

// console.log("relatedDetailsss", relatedDetails)
// console.log("ssnnsssss", isdropdownContentSelected)


useEffect(() => {
  if (selectedValue && subCat.some(item => item.title === selectedValue)) {
    setIsdropdownContentSelected(true);
  }
}, [selectedValue, subCat]);

   return (
    <>
        <div className="">
          {!loading && selectedContent !== "" && (
            <div className="main-video-wrapper">
              {category === "live" ? (
                <LiveVideoPlayer
                  catalogid={selectedCatlog}
                  contentid={selectedContent}
                />
              ) : (
                <>
                  <ShowVideoPlayer
                  isdropdownContentSelected={isdropdownContentSelected}
                  catalogid={selectedCatlog}
                  contentid={selectedContent}
                    loading={loading}
                    setShowThumbnail={setShowThumbnail}
                    showThumbnail={showThumbnail}
                  />
                </>
              )}
                            {subCat.length > 1  && (
                <Container fluid>
                  {/* <div
                    className="performance-menu-container"
                    ref={performanceMenuRef} 
                    onClick={showPerformanceDropdown}
                  >
                    {detailsData?.title || subCat[0]?.title}
                    <SlArrowDown
                      style={{
                        color: "yellow",
                        marginLeft: "5px",
                        fontSize: "12px",
                        fontWeight: "900",
                      }}
                      onClick={showPerformanceDropdown}
                    />
                    {showPerformance && (
                    <div className="performance-menu">
                    <ul style={{ height: subCat.length <= 8 ? "auto" : "200px" }}>
                              {subCat.map((item, index) => (
                                <li key={index}>
                                  <Link to={item.seo_url}>
                                  <li>{item.title}</li>
                                  </Link>
                                </li>
                              ))}
                            </ul>

                    </div>
                    )}
                  </div> */}



<div className="showsFilter" ref={performanceMenuRef}>
      <Row className="align-items-center">
      <Col lg="auto" md="auto" sm="auto" xs="auto" className="d-flex align-items-center">
      {subCat.length > 0 && <p className="mb-0">Seasons:</p>}
    </Col>
        <Col lg="auto" md="auto" sm="auto" xs="auto">
          {subCat.length > 0 && (
            <select
              className=""
              value={selectedValue} 
              onChange={(event) => {
                setIsdropdownContentSelected(true)
                const selectedItem = subCat.find((item) => item.title === event.target.value);
                if (selectedItem) {
                  setSelectedValue(selectedItem.title); 
                  navigate(selectedItem.seo_url); 
                }
                
              }}
            >
              {subCat.map((item, index) => (
                <option key={index} value={item.title}>
                  {item.title}
                </option>
              ))}
            </select>
          )}
        </Col>
      </Row>
    </div>
                </Container>
              )}
            </div>
          )}
        </div>
        
        <div className=""> 
          {!loading && (
            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => setSelectedTab(tab)}
              id="uncontrolled-tab-example"
              className="mb-3 container-fluid pading-left-right-class"
            >
              {isRequiredRelated === 1 && 
              <Tab eventKey="relatedinfo" title="Related">
                <>
               

                  <div className="first-block">
                    <div className="first-slider">
                      <Container fluid className="pading-left-right-class">
                        <Row>
                        <Col xs='auto'>
                            {relatedVideoData &&
                              relatedVideoData.items &&
                              relatedVideoData.total_items_count > 0 && (
                                <h5 className="displaytit">
                                  {relatedVideoData.display_title} &nbsp;
                                  <span className="text-danger">
                                    {relatedVideoData.total_items_count}
                                  </span>
                                </h5>
                              )}
                          </Col>

                          {relatedVideoData &&
                            relatedVideoData.items &&
                            relatedVideoData.total_items_count >= 10 && (
                              <Col
                                style={{
                                  textAlign: "end",
                                  padding: "0px",
                                }}
                              >
                                <span
                                 className="more-heading"
                                 style={{cursor: 'pointer'}}
        onClick={() => {
          let onclickevent = "";  var cate_url = ""; var subcate_url = "";
          if(category === "home"){ 
            cate_url = seo_url;
            subcate_url = main;
          }else{ 
            cate_url = category;  
            subcate_url = seo_url;
          }
          if (relatedVideoData.theme === "movie" || subcate_url === "etv-life" || cate_url === "recipes") {
            onclickevent = () =>  handleRelatedMore(cate_url, relatedVideoData, "movies");
          } else {
            
            onclickevent  = () =>  seeAllItemsVideo(
              relatedVideoData,
              "episode",
              cate_url
            ) 
          }
        
          // Call the onclickevent function here
          onclickevent();
        }}
      >
        More 
        <span>
          <FaAngleDoubleRight />
        </span>
      </span>
                              </Col>
                            )}
                        </Row>
                      </Container>
                      <Container fluid className="left-padding-grid">
                      <div className={`smooth-slider ${relatedlayoutType ? relatedlayoutType : 'related_shows'}`}>
                        {generateSlider(relatedVideoData, commonSettings)}
                        </div>
                      </Container>
                    </div>
                  </div>
                  <div className="first-block">
                    <div className="first-slider">
                      <Container fluid className="pading-left-right-class">
                        <Row>
                        <Col xs='auto'>
                            {episodetypedetails &&
                              episodetypedetails.items &&
                              episodetypedetails.total_items_count > 0 && (
                                <h5>
                                  Episodes &nbsp;
                                  <span className="text-danger">
                                    {episodetypedetails.total_items_count}
                                  </span>
                                </h5>
                              )}
                          </Col>

                          {episodetypedetails &&
                            episodetypedetails.items &&
                            episodetypedetails.total_items_count >= 10 && (
                              <Col
                                style={{
                                  textAlign: "end",
                                  padding: "0px",
                                }}
                              >
                                <span
                                  onClick={() =>
                                    seeAllItems(
                                      episodetypedetails,
                                      "episode",
                                      category
                                    )
                                  }
                                  className="more-heading"
                                >
                                  More
                                  <span>
                                    <FaAngleDoubleRight />
                                  </span>
                                </span>
                              </Col>
                            )}
                        </Row>
                      </Container>
                      <Container fluid className="left-padding-grid">
                      <div className={`smooth-slider ${episodelayoutType ? episodelayoutType : 'related_shows'}`}>
                        {generateSlider(episodetypedetails, commonSettings)}
                        </div>
                      </Container>
                    </div>
                  </div>

                  <div className="first-block">
                    <div className="first-slider">
                      <Container fluid className="pading-left-right-class">
                        <Row>
                        <Col xs='auto'>
                            {promotypedetails &&
                              promotypedetails?.items &&
                              promotypedetails.total_items_count > 0 && (
                                <h5>
                                  Promos &nbsp;
                                  <span className="text-danger">
                                    {promotypedetails.total_items_count}
                                  </span>
                                </h5>
                              )}
                          </Col>
                        </Row>
                      </Container>
                      <Container fluid className="left-padding-grid">
                      <div className={`smooth-slider ${promolayoutType ? promolayoutType : 'related_shows'}`}>
                        {generateSlider(promotypedetails, commonSettings)}
                        </div>
                      </Container>
                    </div>
                  </div>

                  <div className="first-block">
                    <div className="first-slider">
                      <Container fluid className="pading-left-right-class">
                        <Row>
                        <Col xs='auto'>
                            {miniclipsdetails &&
                              miniclipsdetails.items &&
                              miniclipsdetails.total_items_count > 0 && (
                                <h5>
                                  Mini Clips &nbsp;
                                  <span className="text-danger">
                                    {miniclipsdetails.total_items_count}
                                  </span>
                                </h5>
                              )}
                          </Col>
                          {miniclipsdetails &&
                            miniclipsdetails.items &&
                            miniclipsdetails.total_items_count >= 10 && (
                              <Col
                                style={{
                                  textAlign: "end",
                                  padding: "0px",
                                }}
                              >
                                <span
                                  onClick={() =>
                                    seeAllItems(
                                      miniclipsdetails,
                                      "mini_clips",
                                      category
                                    )
                                  }
                                  className="more-heading"
                                >
                                  More
                                  <span>
                                    <FaAngleDoubleRight />
                                  </span>
                                </span>
                              </Col>
                            )}
                        </Row>
                      </Container>
                      <Container fluid className="left-padding-grid">
                      <div className={`smooth-slider ${miniclipslayoutType ? miniclipslayoutType : 'related_shows'}`}>
                        {generateSlider(miniclipsdetails, commonSettings)}
                        </div>
                      </Container>
                    </div>
                  </div>
                  {movieVideoListsData?.catalog_list_items?.map(
                    (relitem, relind) => {
                      return (
                        <div className="first-block">
                          <div className="first-slider">
                            <Container fluid className="pading-left-right-class">
                              <Row>
                              <Col xs='auto'>
                                  <h5 className="PopularMiniClips">
                                    {relitem.display_title} &nbsp;
                                    <span className="text-danger">
                                      {relitem.total_items_count}
                                    </span>
                                  </h5>
                                </Col>
                                {relitem?.total_items_count >= 10 ? (
                                  <Col
                                    style={{
                                      textAlign: "end",
                                      padding: "0px",
                                    }}
                                  >
                                    <span
                                     className="more-heading"
                                     style={{cursor: 'pointer'}}
                                      onClick={() =>
                                        handleMore(
                                          relitem.seo_url,
                                          relitem.catalog_list_items[0],
                                          "all"
                                        )
                                      }
                                    >
                                      More
                                      <span>
                                        <FaAngleDoubleRight />
                                      </span>
                                    </span>
                                    {/* <Link
                                          
                                                to={`/more${relitem.seo_url}`}
                                                className="more-heading"
                                              >
                                              
                                              </Link> */}
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </Container>
                            <Container fluid className="left-padding-grid"> 
                            <div className={`smooth-slider ${(relitem?.web_layout_type ?? relitem?.layout_type) ? (relitem?.web_layout_type ?? relitem?.layout_type) : 'related_shows'}`}>
                              {generateSlider(relitem, commonSettings)}
                              </div>
                            </Container>
                          </div>
                        </div>
                      );
                    }
                  )}

                  {mediaListsDataLoading ?<>
                  {medialistsdata.length > 0 ?<>
                    {medialistsdata?.map((relitem, relind) => {
                      
                    return (
                      <div className="first-block">
                        <div className="first-slider">
                          <Container fluid className="pading-left-right-class">
                            <Row>
                            <Col xs='auto'>
                                <h5 className="PopularMiniClips">
                                  {relitem.display_title} &nbsp;
                                  <span className="text-danger">
                                    {relitem.total_items_count}
                                  </span>
                                </h5>
                              </Col>
                              {relitem?.total_items_count >= 10 ? (
                                <Col
                                  style={{
                                    textAlign: "end",
                                    padding: "0px",
                                  }}
                                >
                                  <Link
                                    to={`/more${relitem.seo_url}`}
                                    className="more-heading"
                                  >
                                    More
                                    <span>
                                      <FaAngleDoubleRight />
                                    </span>
                                  </Link>
                                </Col>
                              ) : (
                                ""
                              )}
                            </Row>
                          </Container>
                          <Container fluid className="left-padding-grid">
                          <div className={`smooth-slider ${(relitem?.web_layout_type ?? relitem?.layout_type) ? (relitem?.web_layout_type ?? relitem?.layout_type) : 'related_shows'}`}>
                            {generateSlider(relitem, commonSettings)}
                            </div>
                          </Container>
                        </div>
                      </div>
                    );
                  })}             
                  </> : <div  className="text-center mt-4"> <Spinner animation="border" variant="danger" /> </div> } </>: <></>}
               
               
                  <div className="first-block">
                    <div className="first-slider">
                      <Container fluid className="pading-left-right-class">
                        <Row>
                        <Col xs='auto'>
                            {relatedDetails.display_title &&
                              relatedDetails.total_items_count > 0 && (
                                <h5 className="displaytit"> 
                                  {relatedDetails.display_title} &nbsp;
                                  <span className="text-danger">
                                    {relatedDetails?.total_items_count}
                                  </span>
                                </h5>
                              )}
                          </Col>
                          {relatedDetails?.total_items_count >= 10 && (
    <Col
      style={{
        textAlign: "end",
        padding: "0px",
      }}
    >
      <span
      className="more-heading"
      style={{cursor: 'pointer'}}
        onClick={() => {
          let onclickevent = "";  var cate_url = ""; var subcate_url = "";
          if(category === "home"){ 
            cate_url = seo_url;
            subcate_url = main;
          }else{ 
            cate_url = category;  
            subcate_url = seo_url;
          }

          if (relatedDetails.theme === "movie" || relatedDetails.theme === "video" || subcate_url === "etv-life" || cate_url === "recipes" || cate_url ==="upcoming-trailers") {
            onclickevent = () =>  handleRelatedMore(cate_url, relatedDetails, "movies",relatedDetails.display_title);
          } else {
            
            onclickevent  = () =>  seeAllItemsVideo(
              relatedDetails,
              "episode",
              cate_url
            ) 
          }
        
          // Call the onclickevent function here
          onclickevent();
        }}
      >
        More 
        <span> 
          <FaAngleDoubleRight />
        </span>
      </span>
    </Col>
  )}

                        </Row>
                      </Container>
                      <Container fluid className="left-padding-grid">
                      <div className={`smooth-slider ${(relatedDetails?.web_layout_type ?? relatedDetails?.layout_type) ? (relatedDetails?.web_layout_type ?? relatedDetails?.layout_type) : 'related_shows'}`}>
                        {generateSlider(relatedDetails, commonSettings)}
                        </div>
                      </Container>
                    </div>
                    {/* <GPTAdTwo /> */}
                    <>
                    {/* <div className='text-center d-lg-block d-md-block d-none'>
      <center>
        <Gpt 
          adUnit="/23111733658/ca-pub-6245873721150554-tag/Test1D" 
          name="div-gpt-ad-1728045293321-0" 
          size={[[960, 90], [728, 90]]}
          sizeMapping={[
            { viewport: [1024, 0], sizes: [[960, 90]] }, // Serve 960x90 for screens 1024px and above
            { viewport: [768, 0], sizes: [[728, 90]] },  // Serve 728x90 for screens between 768px and 1024px (you can choose to include this if needed)
            { viewport: [0, 0], sizes: [] },             // No ad for screens below 768px (mobile view)
          ]}
          onSlotRenderEnded={(event) => {
            console.log("Ad rendered: ", event);
            adSlotRef.current = event.slot; // Capture the slot reference after rendering
          }}
        />
      </center>
    </div> */}
                    </>
                  </div> 
                  <GPTAdThree /> 
                  
                </>
             


             

                  
              </Tab>
              } 
              <Tab
                className="container-fluid pading-left-right-class"
                eventKey="moreinfo"
                title="More Info"
              >
                <>
                <div className="video-title">
                <h3>{detailsData.title}  {detailsData?.release_date_string ? "|" : ""} <span className="release-date"> {detailsData?.release_date_string ? formatReleaseDate(detailsData?.release_date_string) : ""}</span></h3>
      </div>
                  {detailsData.duration_string
                    ? `Duration: ${detailsData.duration_string}`
                    : ""}

                  <div className="">
                    {detailsData?.description
                      ?.split("\n")
                      .map((paragraph, index) => (
                        <p key={index}>{paragraph}</p>
                      ))}
                  </div>

                  {detailsData.cbfc_rating && (
                    <div className="">
                      Rating: <span>{detailsData.cbfc_rating}</span>
                    </div>
                  )}

{detailsData.display_genres && (
  <div className="mt-3">
    <span>Genres: </span> {detailsData.display_genres.join(', ')}
  </div>
)}
                </>
              </Tab>
            </Tabs>
          )}

        </div>

        {!loading && relatedDetails && detailsData && <Footer />}
    </>
  );
};

export default HLSPlayerWithQualityControl;
