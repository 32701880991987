import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import { FaCheckCircle, FaPlay, FaWindowClose } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { access_token, auth_token, BASE_URL } from "../../services/config";

// import "./Fliters.css";
import Header from "../../Componets/Header/Header";
import Footer from "../../Componets/Footer/Footer";
// import useRegionData from "../../Componets/regions/regions";
import Loader from "../../Componets/loader";
import { SkeletonLoader,SkeletonLoadBoxMOre } from "../skeletonLoad/skeletonthumbnail";

import { useSelector } from "react-redux";
import { guestuserid } from "../../App";

const Filters = ({ showFilter, setShowFilter }) => {
  const vegicon = "/images/veg.png";
  const nonvegicon = "/images/nonveg.png";
  const verticaldefultimage = "/images/horizontaldefault.jpg";

  const regionsData = useSelector((state) => state.region.regionsData);

  const [recipesData, setRecipesData] = useState({});
  const [recipeItems, setRecipeItems] = useState([]);
  const [filtereddata, setFiltereddata] = useState([]);
  const [filteredItemsList, setFilteredItemsList] = useState([]);
  const [recipetags, setRecipetags] = useState([]);
  const [vegtag, setVegtag] = useState("");
  const [loading, setLoading] = useState(false);
  const [appliedFilterloading, setAppliedFilterLoading] = useState(false);
  const [vegCount, setVegCount] = useState("");
  const [nonVegCount, setNonVegCount] = useState("");
  const [bothRecipesCount, setBothRecipesCount] = useState("");
  const [nonvegtag, setNonVegtag] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [appliedselectedGenres, setAppliedSelectedGenres] = useState([]);
  const [vegRecipe, setVegRecipe] = useState([]);
  const [nonVegRecipe, setNonVegRecipe] = useState([]);
  const [combinedRecipe, setCombinedRecipe] = useState([]);
  const effectRan = useRef(false)

  // const { region, regiondetails } = useRegionData();
  const ALL_RECIPES_DETAILS = `${BASE_URL}catalogs/recipes/genres.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;

  useEffect(() => {
    if(effectRan.current === false){
      fetchFilterRecipeDetails();
      return () => {
        effectRan.current = true
      }
    }
  }, []);

  const fetchFilterRecipeDetails = async () => {
    try {
      const response = await axios.get(ALL_RECIPES_DETAILS);
      const responseData = response?.data?.data;

      if (response?.status === 200) {
        // console.log("Food items :", responseData);
        setRecipesData(response?.data?.data || {});
        setRecipeItems(response?.data?.data.items || []);
        const tagss = response?.data?.data.items.map((item) => item.tags);
        setRecipetags(response?.data?.data.items.map((item) => item.tags));
        // setVegCount setNonVegCount
        // console.log("tagss :", tagss);
        setVegCount(response?.data?.data.items.map((item) => item.veg));
        setNonVegCount(response?.data?.data.items.map((item) => item.non_veg));
        setBothRecipesCount(
          response?.data?.data.items.map((item) => item.count)
        );
        if (tagss && tagss.length > 0) {
          const bothtags = tagss[0];

          if (bothtags && bothtags.length > 0) {
            const firstTag = bothtags[0];
            const nonvegTag = bothtags[1];
            setVegtag(firstTag);
            setNonVegtag(nonvegTag);
            // console.log("veg tag:", firstTag);
            // console.log("nonvegTag tag:", nonvegTag);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    }
  };

  function getPageName() {
    const path = window.location.pathname;
    const parts = path.split('/'); // Split path by '/'
    return parts[1] ?? "home"; // Return the second part after localhost/
  }
  const getLoginStatus = localStorage.getItem("login_status")
  const getUserId = localStorage.getItem("user_id");
  const param1Value = getPageName();
  const sourcetab = localStorage.getItem("selectedMenuItem");

  const handleGotoPage = async (data, index) => {
    // console.log("lsllslsl", index)
    // console.log("datamainsss", data)
    // console.log("psppspsp", data)
    // console.log("param1Valuejsjs", param1Value)

    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source: sourcetab?.toLowerCase(),
        event_id: 21,
        video_name: data?.title,
        tray_name: "NA",
        platform: "web",
        event_time: new Date().toISOString().slice(0, 19),
        u_id: getLoginStatus === "true" ? getUserId : guestuserid,
        country: regionsData?.country_name,
        content_type: data?.media_type ? data?.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: data?.media_type ? data?.media_type : 'NA',
      });
    }
  };

  const [selectedType, setSelectedType] = useState("non_veg,veg");
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleGenreClick = (genre) => {
    const isSelected = selectedGenres.includes(genre);

    if (selectAll) {
      setSelectAll(false);
      setSelectedGenres((prevSelected) =>
        prevSelected.filter((selectedGenre) => selectedGenre !== genre)
      );
    } else {
      setSelectedGenres((prevSelected) => {
        if (isSelected) {
          return prevSelected.filter(
            (selectedGenre) => selectedGenre !== genre
          );
        } else {
          return [...prevSelected, genre];
        }
      });
    }
  };
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const allGenres = recipeItems.map((recipe) => recipe.name);
      const allGenresAppliedFilter = recipeItems.map(
        (recipe) => recipe.display_title
      );
      setSelectedGenres(allGenres);
      setAppliedSelectedGenres(allGenresAppliedFilter);
    } else {
      setSelectedGenres([]);
      setAppliedSelectedGenres([]);
    }
  };

  // const handleSelectType = async (type) => {
  //   setLoading(true)
  //   setSelectedType(type);
  //   setSelectAll(false);
  //   setSelectedGenres([]);
  //   setAppliedSelectedGenres([]);
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}catalogs/recipes/genres.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&tags=${type}`
  //     );
  //     if(response.status === 200){
  //       setLoading(false)
  //       type === "veg"
  //         ? setVegRecipe(response?.data?.data.items || [])
  //         : setNonVegRecipe(response?.data?.data.items || []);
  //     }
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // };

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    let initialWidth = window.innerWidth;
    let initialHeight = window.innerHeight;
  
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const currentHeight = window.innerHeight;
  
      // Only reload if the width has changed, the page is not in fullscreen mode,
      // and the change is not triggered by exiting fullscreen mode.
      if (!isFullscreen && currentWidth !== initialWidth) {
        window.location.reload();
      }
  
      // Update the initial width and height after resize to avoid repeated reloads
      initialWidth = currentWidth;
      initialHeight = currentHeight;
    };
  
    const handleFullscreenChange = () => {
      // Check if any element is in fullscreen mode
      const fullscreenStatus = !!document.fullscreenElement;
      setIsFullscreen(fullscreenStatus);
  
      // Update initial dimensions when entering or exiting fullscreen mode to prevent reloads
      if (fullscreenStatus) {
        initialWidth = window.innerWidth;
        initialHeight = window.innerHeight;
      }
    };
  
    window.addEventListener('resize', handleResize);
    document.addEventListener('fullscreenchange', handleFullscreenChange);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullscreen]);

  const handleSelectType = async (type) => {
    setLoading(true);
    setSelectedType(type);
    setSelectAll(false);
    setSelectedGenres([]);
    setAppliedSelectedGenres([]);

    try {
      let response;

      if (type === "veg" || type === "non_veg") {
        response = await axios.get(
          `${BASE_URL}catalogs/recipes/genres.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&tags=${type}`
        );
      } else {
        response = await axios.get(
          `${BASE_URL}catalogs/recipes/genres.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`
        );
      }

      if (response.status === 200) {
        setLoading(false);

        if (type === "veg") {
          setVegRecipe(response?.data?.data.items || []);
        } else if (type === "non_veg") {
          setNonVegRecipe(response?.data?.data.items || []);
        } else {
          setCombinedRecipe(response?.data?.data.items || []);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // console.log("veg Recipess", vegRecipe)
  // console.log("non_veg Recipess", nonVegRecipe)
  // console.log("combinedRecipess", combinedRecipe)

  const handleSelectBoth = async (type) => {
    setLoading(true);
    setSelectedType(type);
    setSelectAll(false);
    setSelectedGenres([]);
    setAppliedSelectedGenres([]);
    try {
      const response = await axios.get(
        `${BASE_URL}catalogs/recipes/genres.gzip?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`
      );
      if (response.status === 200) {
        setLoading(false);
        type === "both" && setCombinedRecipe(response?.data?.data.items || []);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // console.log("combinedRecipe", combinedRecipe)
  // console.log("vegRecipe", vegRecipe)
  // console.log("nonVegRecipe", nonVegRecipe)
  const selectedGenresString = selectedGenres.join();

  const FilterRecipes = async (e) => {
    e.preventDefault();
    setAppliedFilterLoading(true);
    // console.log("selectedGenres", selectedGenres);
    const selectedGenresString = selectedGenres.join();
    const filterItems = `${BASE_URL}catalogs/recipes/items.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${selectedGenresString}&page_size=500&sub_genres=${selectedType}`;

    try {
      const response = await axios.get(filterItems);
      if (response.status === 200) {
        setAppliedFilterLoading(false);
        setFiltereddata(response?.data?.data);
        setFilteredItemsList(response?.data?.data?.items);
        // console.log(`filter response:`, response?.data?.data);
        // console.log(`filtered items:`, response?.data?.data?.items);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // https://stagingott.etvwin.com/catalogs/recipes/items.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=q5u8JMWTd2698ncg7q4Q&access_token=Ay6KCkajdBzztJ4bptpW&genres=Traditional,Breakfast,Pickles,Salads,International_Cuisines,Soups,Drinks/Juices,Condiment,Quick_Recipes,Chef_Special,Snacks_Starters,Sweets_Desserts,Indian_Cuisine,Healthy_Food&page_size=500&sub_genres=non_veg,veg

  const handleClearRecipe = async (event, index) => {
    event.preventDefault();
    // console.log(index, "index from handleClearRecipe")
    setLoading(true);
    const updatedGenres = selectedGenres.filter((_, i) => i !== index);
    // console.log(updatedGenres, 'updatedGenres')
    setSelectedGenres(updatedGenres);
    if (updatedGenres.length ===0 ) {
      setShowFilter(false)
    }
    const updatedFilterItem = updatedGenres.join();
    const clearfilterItems = `${BASE_URL}catalogs/recipes/items.gzip?item_language=eng&region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}&genres=${updatedFilterItem}&page_size=500&sub_genres=${selectedType}`;
    // console.log("updatedFilterItemmmm", clearfilterItems)

    try {
      const response = await axios.get(clearfilterItems);
      if (response.status === 200) {
        setLoading(false);
        setFiltereddata(response?.data?.data);
        setFilteredItemsList(response?.data?.data?.items);
        // console.log(`cleared response:`, response?.data?.data);
        // console.log(`cleared items:`, response?.data?.data?.items);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClearAll = () => {
    setSelectedGenres([]);
    setFiltereddata([]);
    setFilteredItemsList([]);
    setShowFilter(false);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const isApplyButtonEnabled = selectedGenres.length > 0;
  const [imageLoadedArray, setImageLoadedArray] = useState(new Array(filtereddata?.items?.length).fill(false));

  // console.log(selectedGenres, "selectedGenres")
  const handleImageLoad = (index) => {
    setImageLoadedArray(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };


  const filteredlayoutType = (filtereddata.web_layout_type ?? filtereddata.layout_type) || 'NA';

  return (
    <>
      {/* <Header /> */}

      {/* {showFilter ? <></> : } */}
      <div className="">
        {filtereddata &&
        filtereddata.items &&
        filtereddata.items.length >= 0 ? (
          <>
            {showFilter && (
              <Row>
                <Col lg={12}>
                  <div className="mb-3">Applied Filters: </div>
                  <div className="AppliedFilters">
                  {selectedGenres &&
                    selectedGenres.map((genre, index) => (
                      <button key={index} className="applied-filters-style">
                        {genre}
                        <FaWindowClose
                          onClick={(e) => handleClearRecipe(e, index)}
                          style={{ margin: "0 5px" }}
                        />
                      </button>
                    ))}
                    </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-4">
                    <button onClick={handleClearAll} className="clear-all-style">
                      CLEAR ALL
                    </button>
                  </div>
                </Col>
              </Row>
            )}

            <Row>
              {loading ? (
               <>
               {filteredlayoutType === "etv-exclusive_banner" ? (
                 <SkeletonLoadBoxMOre height={201} width={648} />
               ) : filteredlayoutType === "tv_shows_banner" ? (
                 <SkeletonLoadBoxMOre height={380} width={1304}/>
               ) : filteredlayoutType === "tv_shows" || filteredlayoutType === "movies" ? (
                 <SkeletonLoadBoxMOre height={180} />
               ) : filteredlayoutType === "top10" ? (
                 <SkeletonLoadBoxMOre height={259} width={194}/>
               ) : filteredlayoutType === "latest_episode" ||
               filteredlayoutType === "episode" ||
               filteredlayoutType === "videos" ||
               filteredlayoutType === "promo" ||
               filteredlayoutType === "mini_clips" ||
               filteredlayoutType === "movie_poster" ||
               filteredlayoutType === "mini_movie_poster" ||
               filteredlayoutType === "movie_poster" ||
               filteredlayoutType === "recipe" ? (
                 <SkeletonLoadBoxMOre height={108} width={185} />
               ) : (
                 ""
               )}
                 <SkeletonLoader height={10} width={182} />
             
               
             </> 
              ) : (

            

                filteredlayoutType === "recipe" &&
                filteredItemsList.map((item, index) => {
                  // console.log("filteredItemsListsshsh", filteredItemsList)
                  // console.log("item", item.sub_genres[0]);
                  return (
                    <Col lg={2} md={4} sm={4} xs={6} key={index}>
                      <Link onClick={() => handleGotoPage(item, index)} to={`${item.seo_url}`}>
                        <div className="carousel-car">
                          <div className="extra lock-icon-class cms-container">
                            {!loading ? (
                              <>
                              <div className="thumbnailMovieCard">
                                {/* <img
                                  src={item?.thumbnails?.high_16_9?.url}
                                  width="100%"
                                  alt={`Slider ${index}`}
                                  className="img-border-radis"
                                /> */}
<img
  src={imageLoadedArray[index] ? item?.thumbnails?.high_16_9?.url : verticaldefultimage}
  width="100%"
  onLoad={() => handleImageLoad(index)}
  onError={(e) => {
    e.target.src = verticaldefultimage; // Fallback to default image if loading fails
    handleImageLoad(index); // Mark as loaded to prevent further attempts
  }}
  loading={!imageLoadedArray[index] ? "eager" : "lazy"}
  alt={item.layout_type || "default image"}
/>



{imageLoadedArray[index] && (
  <>
    {(item?.media_type === "video" ||
      item?.media_type === "episode" ||
      item?.media_type === "latest_episode" ||
      item?.media_type === "best_scenes" ||
      item?.media_type === "promo" ||
      item?.media_type === "mini_clips") && (
      <div className="iconcame">
        <FaPlay />
      </div>
    )}

  </>
)}
                              </div>

                              {imageLoadedArray[index] && (
  <>

    <p style={{ fontSize: "14px", marginTop: "" }}>{item?.title}</p>
  </>
)}
                              </>

                            ) : (
                              <SkeletonLoader />
                            )}

{imageLoadedArray[index] && (
  <>
 <div className="FoodType">
                              <img
                                src={
                                  item?.sub_genres?.includes("veg")
                                    ? vegicon
                                    : nonvegicon
                                }
                                className=""
                                loading="lazy"
                                alt=""
                              />
                            </div>

                            {item?.access_control?.is_free === false && (
                                    <div className="lock-postion-class"></div>
                                  )}
  </>
)}

                           
                          </div>
                         
                        </div>
                      </Link>
                    </Col>
                  );
                })
              )}

              {/* {filteredlayoutType === "recipe" &&
                filteredItemsList.map((item, index) => {
                  return (
                    <>
                      <Col lg={2} md={4} sm={3}>
                        <Link to={`${item.seo_url}`} key={index}>
                          <div className="carousel-car">
                            <div className="extra lock-icon-class cms-container">
                              <img
                                src={item?.thumbnails?.high_16_9?.url}
                                width="100%"
                                alt={`Slider ${index}`}
                                className="img-border-radis"
                              />
                              <div className="lock-postion-class"></div>
                            </div>
                            <p style={{ fontSize: "13px", marginTop: "-20px" }}>
                              {item?.title}
                            </p>
                          </div>
                        </Link>
                      </Col>
                    </>
                  );
                })} */}
            </Row>
          </>
        ) : (
          <Container fluid className="pading-left-right-class">
            {appliedFilterloading ? (
              <Loader />
            ) : (
              <>
                <Row>
                  <Col lg={11} md={10} sm={10} xs={9}>
                    <h6>Filters</h6>
                  </Col>
                  <Col lg={1} md={2} sm={2} xs={3}>
                    <div className="text-center">
                      <FaWindowClose onClick={handleCloseFilter} />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                  <div className="my-pre-main">
                  <div className="my-pre-main-contant">
                    <div className="genre-main">
                      <Row className="buttons-t-b">
                        <Col lg={4} md={4} xs={4}>
                          <div
                            className={`done-button-100 ${
                              selectedType === "veg" && "selected"
                            }`}
                          >
                            <Link to="" onClick={() => handleSelectType("veg")}>
                              Veg &nbsp;
                              {selectedType === "veg" && (
                                <FaCheckCircle style={{ fontSize: "20px" }} />
                              )}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4} md={4} xs={4}>
                          <div
                            className={`done-button-100 ${
                              selectedType === "non_veg" && "selected"
                            }`}
                          >
                            <Link
                              to=""
                              onClick={() => handleSelectType("non_veg")}
                            >
                              Non-Veg &nbsp;
                              {selectedType === "non_veg" && (
                                <FaCheckCircle style={{ fontSize: "20px" }} />
                              )}
                            </Link>
                          </div>
                        </Col>
                        <Col lg={4} md={4} xs={4}>
                          <div
                            className={`done-button-100 ${
                              selectedType === "non_veg,veg" && "selected"
                            }`}
                          >
                            <Link
                              to=""
                              onClick={() => handleSelectType("non_veg,veg")}
                            >
                              Both &nbsp;
                              {selectedType !== "veg" &&
                                selectedType !== "non_veg" && (
                                  <FaCheckCircle style={{ fontSize: "20px" }} />
                                )}
                            </Link>
                          </div>
                        </Col>
                      </Row>

                      <div className="select-all">
                        <form className="" onSubmit={handleSubmit}>
                          <div className="radios-left">
                            <label className="checkboxcolorsize">
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                              />
                              <span className="checkmarks"></span>
                            </label>
                          </div>
                          <div className="radios-right">
                            <Link onClick={handleSelectAllChange}>
                              Select All
                            </Link>
                          </div>
                        </form>
                      </div>

                      <div className="genre-main-content">
                        <Row>
                          {loading && (
                            <div className="text-center mt-4">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          )}

                          {!loading &&
                            selectedType === "veg" &&
                            vegRecipe.map((recipe, index) => (
                              <Col lg="3" md="4" sm="6" xs="12" key={recipe.id}>
                                <div className="come">
                                  <Link
                                    className={`come-button-filters ${
                                      selectedGenres.includes(recipe.name)
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleGenreClick(recipe.name)
                                    }
                                  >
                                    {selectedGenres.includes(recipe.name) && (
                                      <div className="me-2">
                                        <FaCheckCircle
                                          style={{ fontSize: "20px" }}
                                        />
                                      </div>
                                    )}
                                    <div className="foodTitles">
                                      {recipe.display_title}
                                      <p>
                                        {selectedType === "veg" &&
                                          recipe.veg &&
                                          ` ${recipe.veg} Videos`}
                                        {selectedType === "non_veg" &&
                                          recipe.non_veg &&
                                          ` ${recipe.non_veg} Videos`}
                                        {selectedType !== "veg" &&
                                          selectedType !== "non_veg" &&
                                          recipe.count &&
                                          `${recipe.count} Videos`}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                            ))}

                          {!loading &&
                            selectedType === "non_veg" &&
                            nonVegRecipe.map((recipe, index) => (
                              <Col lg="3" md="4" sm="6" xs="12" key={recipe.id}>
                                <div className="come">
                                  <Link
                                    className={`come-button-filters ${
                                      selectedGenres.includes(recipe.name)
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleGenreClick(recipe.name)
                                    }
                                  >
                                    {selectedGenres.includes(recipe.name) && (
                                      <div className="me-2">
                                        <FaCheckCircle
                                          style={{ fontSize: "20px" }}
                                        />
                                      </div>
                                    )}
                                    <div className="foodTitles">
                                      {recipe.display_title}
                                      <p>
                                        {selectedType === "veg" &&
                                          recipe.veg &&
                                          ` ${recipe.veg} Videos`}
                                        {selectedType === "non_veg" &&
                                          recipe.non_veg &&
                                          ` ${recipe.non_veg} Videos`}
                                        {selectedType !== "veg" &&
                                          selectedType !== "non_veg" &&
                                          recipe.count &&
                                          `${recipe.count} Videos`}
                                      </p>
                                    </div>
                                  </Link>
                                </div>
                              </Col>
                            ))}

                          {!loading &&
                            selectedType === "non_veg,veg" &&
                            (recipeItems || combinedRecipe).map(
                              (recipe, index) => (
                                <Col
                                  lg="3"
                                  md="4"
                                  sm="6"
                                  xs="12"
                                  key={recipe.id}
                                >
                                  <div className="come">
                                    <Link
                                      className={`come-button-filters ${
                                        selectedGenres.includes(recipe.name)
                                          ? "selected"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleGenreClick(recipe.name)
                                      }
                                    >
                                      {selectedGenres.includes(recipe.name) && (
                                        <div className="me-2">
                                          <FaCheckCircle
                                            style={{ fontSize: "20px" }}
                                          />
                                        </div>
                                      )}
                                      <div className="foodTitles">
                                        {recipe.display_title}
                                        <p>
                                          {selectedType === "veg" &&
                                            recipe.veg &&
                                            ` ${recipe.veg} Videos`}
                                          {selectedType === "non_veg" &&
                                            recipe.non_veg &&
                                            ` ${recipe.non_veg} Videos`}
                                          {selectedType !== "veg" &&
                                            selectedType !== "non_veg" &&
                                            recipe.count &&
                                            `${recipe.count} Videos`}
                                        </p>
                                      </div>
                                    </Link>
                                  </div>
                                </Col>
                              )
                            )}
                        </Row>
                      </div>
                    </div>

                    <ul className="buttons">
                      <li>
                        <div className="canle-button">
                          <Link to="" onClick={handleClearAll}>
                            Cancel
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div>
                          <button
                            className="fdone-button"
                            disabled={!isApplyButtonEnabled}
                            onClick={FilterRecipes}
                            to=""
                          >
                            Apply
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                  </Col>
                </Row>

                
              </>
            )}
          </Container>
        )}

        {/* {recipeItems && filteredItemsList && filteredItemsList.length > 0 && <Footer />} */}
      </div>
    </>
  );
};

export default Filters;
