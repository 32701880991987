import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateProfiles: false,
};

export const updateMultiProfileSlice = createSlice({
  name: "update multi profile",
  initialState,
  reducers: {
    setUpdateProfiles: (state, action) => {
      state.updateProfiles = action.payload.updateProfiles ;
    },
  },
});

export const { setUpdateProfiles } = updateMultiProfileSlice.actions;

export default updateMultiProfileSlice.reducer;
