import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { FaPlay } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { guestuserid } from '../../App';
import { setMainTraynamedata } from '../../Redux/Slice/navlinkcallSlice';
import { setThroughSeach } from '../../Redux/Slice/throughSearchSlice';

const FilterEpisodesResult = ({ renderFiltereditemTitle, imageLoadedArrayMedialist, verticaldefultimage, handleImageLoadMedialist, filterdataLoading }) => {
  const { filterEpisodeData, filterEpisodeItemsData } = useSelector(
    (state) => state.filter_episode_details
  );
  const dispatch = useDispatch()
  const regionsData = useSelector((state) => state.region.regionsData);
  const getUserId = localStorage.getItem("user_id");
  const getLoginStatus = localStorage.getItem("login_status");
  function getPageName() {
    const path = window.location.pathname;
    const parts = path.split('/'); // Split path by '/'
    return parts[1] ?? "home"; // Return the second part after localhost/
}

const param1Value = getPageName();

 const [imageLoadedArray, setImageLoadedArray] = useState(
    new Array(0).fill(false)
  );

  const handleImageLoad = (index) => {
    setImageLoadedArray((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleGotoPage = async (data, index, filterdata) => {
  //  console.log("datajsjsjs", data)
  //  console.log("filterdatasdsds", filterdata)
    dispatch(setThroughSeach({ throughSeach: false }));
    dispatch(setMainTraynamedata({ maintraynamedata:  filterdata}));

    if (window && window.sdk && window.sdk.trackEvent) {
      window.sdk.trackEvent("artwork_tapped", {
        source: param1Value === "shows" ? "tv shows" : "NA",
        video_name: data?.title,
        tray_name: filterdata?.title ? filterdata?.title : "NA",
        platform: "web",
        event_id: 21,
        event_time: new Date().toISOString().slice(0, 19),
        u_id: getLoginStatus === "true" ? getUserId : guestuserid,
        country: regionsData?.country_name,
        content_type: data?.media_type ? data?.media_type : 'NA',
        position_within_tray: index + 1,
        artwork_type: data?.media_type ? data?.media_type : 'NA',
      });
    }
  };

  return (
    <div className="">
        <Container fluid className="pading-left-right-class">
          <Row>
            <Col lg="12"> 
              <div className='first-slider'>
              <h6>
  Showing Episodes Result Of &nbsp;
  <span style={{ color: "#f00" }}>
    {typeof renderFiltereditemTitle[0] === "undefined" ? filterEpisodeData.title : renderFiltereditemTitle[0]}
  </span>
</h6>

              </div>
              
            </Col>
            <Col className='d-none' style={{ textAlign: "end", padding: "0px" }}></Col>
          </Row>
          <Row className="slider-two">
            {filterEpisodeItemsData.length > 0 ? filterEpisodeItemsData.map((innerlist, innerIndex) => {
              let releaseDate = new Date(innerlist.release_date_string);
              const day = releaseDate.getDate().toString().padStart(2, "0");
              const year = (releaseDate.getFullYear() % 100).toString().padStart(2, "0");
              const monthAbbreviation = releaseDate.toLocaleString("default", { month: "short" });
              let formattedDate = `${day} ${monthAbbreviation} ${year}`;

              return (
                <Col lg="2" md="3" sm="4" xs="4" key={innerIndex}>
                  <Link to={`${innerlist.seo_url}`}>
                    <div  onClick={(event) => {
                  handleGotoPage(innerlist, innerIndex, filterEpisodeData);
                }} className="carousel-car continuewatch">
                      <div className="extra lock-icon-class cms-container  cw-card">
                        <div className="thumbnailMovieCard relateddet">
                        <img
  src={
    imageLoadedArray[innerIndex]
      ? innerlist?.thumbnails?.medium_16_9?.url 
      : verticaldefultimage
  }
  onLoad={() => handleImageLoad(innerIndex)}
  onError={(e) => {
    e.target.src = verticaldefultimage; // Fallback to default image if loading fails
    handleImageLoad(innerIndex); // Optionally mark as loaded
  }}
  className="img-border-radis"
  loading={!imageLoadedArray[innerIndex] ? "eager" : "lazy"}
  alt={innerlist?.title}
/>


{imageLoadedArray[innerIndex] && (
  <>
{["episode", "latest_episode", "mini_clips", "video"].includes(innerlist?.media_type) && (
  <div className="iconcame">
    <FaPlay />
  </div>
)}
                          {!innerlist?.access_control?.is_free && <div className="lock-postion-class"></div>}
  </>
)}


                         

                        </div>
                        {imageLoadedArray[innerIndex] && (
  <>
  {innerlist.show_meta_data === 'yes' && innerlist?.media_type && (
                            <p className='displayTitle'>{innerlist?.title} | <span>{formattedDate}</span></p>
                          )}
  </>
)}

                      
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            }) : <div className='text-center my-5'>No episodes found for selected date range</div>}
          </Row>
        </Container>

        {filterdataLoading && 
     <div
    className="text-center mt-4"
    style={{
      position: "fixed",
      top: "60%",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1050, 
    }}
  >
    <Spinner animation="border" variant="danger" />
  </div>
}


    </div>
  );
};

export default FilterEpisodesResult;
