import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    manageProfileClicked: false,
    profilePicture: "",
    selectedProfile: "",
    profileName: "",
    selectedProfileId: "",
    // traynamedata: {},
  };
  
  export const multiProfileSlice = createSlice({
    name: "manage profiles",
    initialState,
    reducers: {
      setManageProfileClicked: (state, action) => {
          state.manageProfileClicked = action.payload.manageProfileClicked  
        },

        setProfilePicture: (state, action) => {
          state.profilePicture = action.payload.profilePicture  
        },

        setSelectedProfile: (state, action) => {
          state.selectedProfile = action.payload.selectedProfile  
        },

        setProfileName: (state, action) => {
          state.profileName = action.payload.profileName  
        },

        setSelectedProfileId: (state, action) => {
          state.selectedProfileId = action.payload.selectedProfileId  
        },

        
    },
  });
  
  export const {manageProfileClicked, setManageProfileClicked, setProfilePicture, setSelectedProfile, setProfileName, setSelectedProfileId} = multiProfileSlice.actions;
  
  export default multiProfileSlice.reducer;