import React, { useEffect, useState, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Accordion from "react-bootstrap/Accordion";
import "react-toastify/dist/ReactToastify.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaApple, FaGooglePlay} from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import SearchFilter from "./Search";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import LoginScreen from "../../pages/login/login";
import { LiaUserEditSolid } from "react-icons/lia";

import Register from "../../pages/Register/Register";
import Logout from "../../pages/logout/logout";
import RegisterByMobile from "../../pages/mobilesignup/registerbymobile";
import axios from "axios";
import {
  BASE_URL,
  PROFILE_DETAILS,
  TOP_MENU,
  access_token,
  auth_token,
} from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../Redux/Slice/authSlice";
import LogoutAll from "../../pages/logout/logoutall";
import { setModalClose, setModalOpen } from "../../Redux/Slice/loginModalSlice";
import { GoKey } from "react-icons/go";
import { setActivePlanData } from "../../Redux/Slice/activePlanSlice";

// import { firebaseAnalytics,firebasemessaging } from '../../firebase';
import { setShowLogoutAllModal } from "../../Redux/Slice/logoutAllModalSlice";
import { setShowLogoutModal } from "../../Redux/Slice/logoutModalSlice";
import { setShowRegisterModal } from "../../Redux/Slice/registermodalSlice";
import { setShowMobileRegisterModal } from "../../Redux/Slice/mobileregisterModalSlice";
import { setAccountData, setAccountErrorData } from "../../Redux/Slice/accountsDataSlice";
import { setShowSubscribeButtonStatus } from "../../Redux/Slice/subscribeStatusSlice";
import { setMenuListData } from "../../Redux/Slice/menulistSlice";
import { setNavItemCalled, setNavItemname } from "../../Redux/Slice/navlinkcallSlice";
import { setManageProfileClicked, setProfileName, setProfilePicture, setSelectedProfile, setSelectedProfileId } from "../../Redux/Slice/multiprofileSlice";
import { FaChevronRight, FaPencil } from "react-icons/fa6";

const Header = () => {
const logoutnew = "/images/logout.png";
const logo = "/images/win-logo.png";
const newlogo = "/images/etv-logo-new.png";
const logo25 = "/images/logo-25.png";
const defaultavatar = "/images/defaultavatar.jpg";
const { accountData } = useSelector(
  (state) => state.account_data
);
const defaultanime = "/images/defaultanime.png";

const [clickedDownloadtab, setClickedDownloadtab] = useState(false)
// console.log("accountDatahshsh", accountData)
const [isPopupVisible, setIsPopupVisible] = useState(true);
const {redeemData} = useSelector(
  (state) => state.redeem_coupon
);
const [multiProfiledata, setMultiProfiledata] = useState([]);
const [defaultprofiledata, setDefaultprofiledata] = useState({});
const getaccessToken = useSelector(
  (state) => state.accesstoken_data.accessTokenData
);
const closePopup = () => {
  setIsPopupVisible(false);
};

const handledownload = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            // alert("ios");
            window.location.href = "https://itunes.apple.com/app/id1245077673";
        }

        if (/android/i.test(userAgent)) {
            // alert("android");
            window.location.href = "https://play.google.com/store/apps/details?id=com.etvwin.mobile";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.href = "https://itunes.apple.com/app/id1245077673";
        }
};

// console.log("accountData", accountData)
// console.log("redeemDatasss", redeemData)
  const [isSticky, setIsSticky] = useState(false);
  const regionsData = useSelector((state) => state.region.regionsData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const effectRan = useRef(false)
  const {loginDetails} = useSelector(state => state?.signin_data || {})
  // console.log("loginDetailsjsjs", loginDetails)
  const {otpVerifyData} = useSelector(
    (state) => state.signup_data
  ); 
  const {emailRegisterData} = useSelector(state => state?.email_data || {})
  const getEmailId = loginDetails?.email_id || emailRegisterData?.email_id


  const { sessionId } = useSelector((state) => state.auth);
  let sessionID = localStorage.getItem("session_id") ? localStorage.getItem("session_id") : sessionId
  // console.log("sessionIDsjjs", sessionID)
  // localStorage.setItem("showSubscripeButton", true);
  const isUserLoggedInStatus = localStorage.getItem("login_status");
  // console.log(showSubscribeButton, "subscribeButton");
  const fetchActivePlans = async () => {
    try {
      const nextApiUrl = `${BASE_URL}users/${sessionID}/user_plans?region=${regionsData?.country_code2}&auth_token=${auth_token}&access_token=${access_token}`;
      const response = await axios.get(nextApiUrl);
      const responsedata = response?.data?.data;

      if (response?.status === 200) {
        //  console.log("subscription_plas_api", subscription_plas_api);
        // console.log(`User Active Plan `, response.data.data);
        dispatch(setActivePlanData({ activePlanData: response?.data?.data }));
        if (responsedata && responsedata.length > 0) {
          dispatch(setShowSubscribeButtonStatus({ showSubscribeButtonStatus: true }))
          // setactivePlanId(responsedata[0].plan_id);
          localStorage.setItem("showSubscripeButton", false);
          //  alert(nextApiUrl);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
      // toast.error(error?.message);
    } finally {
      // setLoading(false);
    }
  };

  // useEffect(() => {
  //   if(effectRan.current === false){
  //     if (sessionID) {
  //       fetchActivePlans();
  //       const isUserLoggedIn = localStorage.getItem("login_status");
  //       dispatch(setAuth(Boolean(isUserLoggedIn)));
  //     }
  //     return () => {
  //       effectRan.current = true
  //     }
  //   }
  // }, [sessionID]);

  const { showSubscribeButtonStatus } = useSelector(
    (state) => state.subscribebuttonstatus
  );

  // console.log("showSubscribeButtonStatus", showSubscribeButtonStatus)


  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showLoginOTPPopup, setShowLoginOTPPopup] = useState(false);

  const [firstNameInfo, setFirstNameInfo] = useState("");
  const [profileInfo, setProfileInfo] = useState({});

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuitemslist, setMenuitemslist] = useState([]);
  const handleClose = () => setMenuOpen(false);

  useEffect(() => {
    fetchTopMenuDetails();
    // if(effectRan.current === false){
    //   return () => {
    //     effectRan.current = true
    //   }
    // }
  }, [accountData, isUserLoggedInStatus]);
  
  const fetchTopMenuDetails = async () => {
    try {
      const response = await axios.get(TOP_MENU);

      const menuitemslistd = response?.data?.data?.catalog_list_items;

      if (response?.status === 200) {

        setMenuitemslist(menuitemslistd);
        dispatch(setMenuListData({ menuListData: response?.data?.data?.catalog_list_items }));
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
      // toast.error(error?.message);
    }
  };
  const handleLogin = () => {
    dispatch(setModalOpen())
    dispatch(setShowRegisterModal({ showRegisterModal: false }))
    dispatch(setShowMobileRegisterModal({ showMobileRegisterModal: false }))
  };



  const handleLogout = () => {
    setMenuOpen(false)
    dispatch(setShowLogoutModal({ showLogoutModal: true }))
  };

  const handleLogoutAll = () => {
    setMenuOpen(false)
    dispatch(setShowLogoutAllModal({ showLogoutAllModal: true }))
  };

  const handleOtpPopupClose = () => {
    setShowOTPPopup(false);
  };

  const handleLoginOtpPopupClose = () => {
    setShowLoginOTPPopup(false);
    dispatch(setModalClose());
  };


  // const fetchProfileDetails = async () => {
  //   try {
  //     const nextApiUrl = PROFILE_DETAILS;
  //     const response = await axios.get(nextApiUrl);
  //     if (response?.status === 200) {
  //       dispatch(setAccountData({ accountData: response.data.data}));
  //     }
  //   } catch (error) {
  //     dispatch(setAccountErrorData({ accountErrorData: error.response.data}));
  //   } finally {
  //     // setLoading(false);
  //   }
  // };


  // useEffect(() => {
  //   if(effectRan.current === false){
  //     fetchProfileDetails()
  //     return () => {
  //       effectRan.current = true
  //     }
  //   }
  // }, []);



  


  useEffect(() => {
    const sendregionTopic = async () => {
        try {
            const fcm_token_get = localStorage.getItem('fcm_token');
            const fcmToken = JSON.parse(fcm_token_get);

            if (!fcmToken) {
                return null;
            }

            const data = {
                token: fcmToken,
                topic: regionsData?.country_code2
            };
            const jsondata = JSON.stringify(data);
            const notiUrl = `${BASE_URL}create_fcm_topic?&auth_token=${auth_token}&access_token=${access_token}&region=${regionsData?.country_code2}`;
            const response = await axios.post(
              notiUrl,
              jsondata
          );

          return response?.data;
        } catch (error) {
            throw new Error(error); // Rethrow the error
        }
    };

    const timerId = setTimeout(async () => {
        try {
            const response = await sendregionTopic();
            // console.log("sendregionTopic Resp:", response); // Handle the response as needed
        } catch (error) {
            console.error("Error:", error); // Handle errors
        }
    }, 10000);

    return () => clearTimeout(timerId);
}, []);


// useEffect(() => {
//   try {
//       const currentURL = window.location.href;
//       const decodedUrl = decodeURIComponent(currentURL);
//       const regex = /video_title=([^&]+)/; 
//       const match = decodedUrl.match(regex);
      
//       if (match) { // Check if "video_title" parameter is found
//           const videoTitleParam = match[1];
//           //const firebaseAnalytics = $window.firebase.analytics();
//           const userAttr = {};
//           // $rootScope.sourceTab = 'push notification';
//           // $rootScope.viewingSource = 'NA';
//           // $rootScope.tray_id = 'NA';

//           firebaseAnalytics.logEvent('Notification_open', {
//               video_name: videoTitleParam ? videoTitleParam : 'NA'
//           });
//           // if (localStorage.getItem('userdata')) {
//           //     userAttr.u_id = JSON.parse(localStorage.getItem('userdata')).user_id;
//           //     userAttr.country = regionsData?.country_code2;
//           // } else {
//           //     userAttr.country = regionsData?.country_code2;
//           // }

//           // $window.sdk.trackEvent('notification_open', {
//           //     video_name: videoTitleParam ? videoTitleParam : 'NA',
//           //     event_id: 12,
//           //     event_time: new Date().toISOString().slice(0, 19),
//           // });
//       }
//   } catch (error) {
//       console.log(error);
//   }
// }, []);


// console.log("accountData", accountData.isUserSubscribed)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleManageProfile = () => {
    dispatch(setManageProfileClicked({ manageProfileClicked: true }));
  }

  const handleSwitchProfile = () => {
    setMenuOpen(false)
    dispatch(setManageProfileClicked({ manageProfileClicked: false }));
  }

  

  const renderNavigationLinks = () => {
    const handleClick = (link) => {
      setMenuOpen(false)
      localStorage.setItem("navtab", link?.display_title)
      dispatch(setNavItemCalled({ navItemCalled: true }));
      dispatch(setNavItemname({ navitemname:  link}));
      // console.log("hshhshs", link?.display_title)
      localStorage.setItem("selectedMenuItem", link?.display_title);
    };

    return menuitemslist?.map((link) => (
      <Nav.Link
        as={NavLink}
        key={link.friendly_id}
        to={`/${link.friendly_id}`}
        activeclassname="active-link"
        onClick={() => handleClick(link)}
        style={{ textTransform: "uppercase" }}
      >
        {link.display_title}
      </Nav.Link>
    ));
  };

  const handleNavigation = (path) => {
    // Handle navigation to the specified path
    navigate(path);
    // Add your navigation logic here
  };

  const handleNavigationforProfile = (tab) => {
    navigate(`/profile?tab=${tab}`); // Dynamically append the tab parameter
  };
  

  const handleMobileNavigationforProfile = (tab) => {
    setMenuOpen(false)
    navigate(`/profile?tab=${tab}`); // Dynamically append the tab parameter
 
  };
  
  


  // if(loginDetails?.email_id){
  //   window.location.reload()
  //  }
  // console.log("loginDetails", loginDetails)




  
  useEffect(() => {
    handleProfileDataList();
    // if (effectRan.current === false) {
    
    //   return () => {
    //     effectRan.current = true;
    //   };
    // }
  }, [sessionID]);

     const { manageProfileClicked } = useSelector((state) => state.manage_profile);
    
    const [isEditMode, setIsEditMode] = useState(manageProfileClicked || false); 
   

  useEffect(() => {
    setIsEditMode(manageProfileClicked);
  }, [manageProfileClicked]);

  const handleEditProfiles = () => {
    setIsEditMode(!isEditMode);
    dispatch(setManageProfileClicked({ manageProfileClicked: !isEditMode }));
  };




const handleProfileDataList = async () => {
  const multiprofileurl = `${BASE_URL}/users/${sessionID}/profile_list?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;

  try {
    const response = await axios.get(multiprofileurl);

    const multiprofiledata = response?.data?.profiles;

    if (response?.status === 200) {
      // console.log("Multismsmmsmsm:", response);

      setMultiProfiledata(multiprofiledata);

    }
  } catch (error) {
    console.error("Error fetching data:", error?.message);
  }
};


  const handleProfileClick = (profile) => {
    navigate(`/edit-profile/${profile.profile_id}`, { state: { profile } });
  };


  const handleProfileSwitch = async (profile) => {

    // console.log("smnsmsmsm", profile)
    const defaultselectionurl = `${BASE_URL}/users/${sessionID}/${profile?.profile_id}/default_selection?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;

    try {
      const response = await axios.get(defaultselectionurl);
      // console.log("Profile switched successfully:", response.data);
      const selected = multiProfiledata.find((prof) => prof.profile_id === profile?.profile_id);
      // console.log("selectedsss:", selected);
      dispatch(setProfilePicture({ profilePicture: selected.profile_picture }));
      dispatch(setSelectedProfile({ selectedProfile: profile?.profile_id }));
      dispatch(setProfileName({ profileName: selected.firstname }));
      dispatch(setSelectedProfileId({ selectedProfileId: profile?.profile_id }));
      localStorage.setItem("selectedProfileId", profile?.profile_id)
      localStorage.setItem("profilePicture", selected.profile_picture)
      localStorage.setItem("profileName", selected.firstname)
      // await AsyncStorage.setItem('selectedProfileId', profile_id); // Store selected profile ID
      // navigation.navigate("TabNav");
      navigate("/")
    } catch (error) {
      console.error("Error switching profile:", error.response?.data || error.message);
    }
  };


  const { profilePicture, selectedProfile, profileName, selectedProfileId } = useSelector(
    (state) => state.manage_profile
  );

  const profileavatar = localStorage.getItem("profilePicture")
  const profileid = localStorage.getItem("selectedProfileId")
  const profilename = localStorage.getItem("profileName")
  
  // console.log("profilePicturesjsjsj", profileavatar)
  // console.log("profileNamesss", profileavatar)
  // console.log("profileNamesss", profileavatar)

  const RenderProfileMenu = () => {
    if (isUserLoggedInStatus === "true") {
      const profileimg = `${window?.location?.origin}/images/profile-img.png`;
      const profileimgedit = `${window?.location?.origin}/images/profile-edit.png`;
      const subscriimg = `${window?.location?.origin}/images/subscri.png`;
      const watchlistimg = `${window?.location?.origin}/images/watch-list.png`;
      const transactionsimg = `${window?.location?.origin}/images/my-transactions.png`;
      const activeimg = `${window?.location?.origin}/images/active-tv.png`;
      const preferimg = `${window?.location?.origin}/images/my-prefer.png`;
// console.log("accountDatashshs", accountData)
      return (
        <>
          <li className="d-flex">
            <div className="profile-img-class">
            <img
  src={((profilePicture || profileavatar) == "null" ? defaultanime : (profilePicture || profileavatar)) || defaultavatar}
  alt=""
/>

            </div>

            <NavDropdown id="nav-dropdown">
            <NavDropdown.Item eventKey="4.1" onClick={handleSwitchProfile}>
  <Link to="/profiles" className="switch-profile-item">

  <img
  src={((profilePicture || profileavatar) == "null" ? defaultanime : (profilePicture || profileavatar)) || defaultavatar}
  alt=""
  className="switchprofile"
/>
    
    <div className="profile-inf">
      <p style={{ marginBottom: '3px' }}>{(profilename ? profilename : accountData?.firstname) || ""}</p>
      <p className="profile-action">Switch Profile <FaChevronRight style={{marginTop: "-2px", fontSize: "12px"}} /> </p>
    </div>
  </Link>
</NavDropdown.Item>


{/* <NavDropdown.Item eventKey="4.2">
    <div className="profile-dropdown-row">
      {multiProfiledata.map((profile) => {
        return(
          <div className="text-center"  style={{position:"relative"}} >
          <div
          className={`profile-car ${
            profile.profile_id === (selectedProfileId || profileid) ? "active-profile" : ""
          }`}
          key={profile.profile_id}
          onClick={() => handleProfileSwitch(profile)}
        >
          <img
            src={profile.profile_picture}
            alt={profile.firstname}
            className="profile-image"
          />
        
          
        </div>
        <p className="profile-name-text">{profile.firstname}</p>
          </div>
        )
      })}

     <div className="text-center"  style={{position:"relative"}}>
     <div className="profile-card add-profil" onClick={() => navigate("/updateprofile")}>
        <div className="add-profile-icon">+</div>
      </div>
      <p className="add-profile-text">Add Profile</p>
     </div>
        
    </div>
  </NavDropdown.Item> */}




<NavDropdown.Item eventKey="4.2" onClick={handleManageProfile}>
  <Link to="/profiles">
    <LiaUserEditSolid style={{ color: 'red', fontSize: '20px' }} />
    &nbsp;Manage Profile
  </Link>
</NavDropdown.Item>

              <NavDropdown.Item eventKey="4.1" onClick={() => handleNavigationforProfile("info")} >
                <Link to="/profile?tab=info">
                  <img src={profileimgedit} className="drop-img" alt="" />
                  &nbsp;My Profile
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2"  onClick={() => handleNavigationforProfile("subscription")}  >
                <Link to="/profile?tab=subscription">
                  <img src={subscriimg} className="drop-img" alt="" />
                  &nbsp;Subscription
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3" onClick={() => handleNavigation("/watchlater")} >
                <Link to="/watchlater">
                  <img src={watchlistimg} className="drop-img" alt="" />
                  &nbsp;My List
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3" onClick={() => handleNavigationforProfile("transactions")} >
                <Link to="/profile?tab=transactions">
                  <img src={transactionsimg} className="drop-img" alt="" />
                  &nbsp;My Transactions
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="4.3"  onClick={() => handleNavigation("/activatetv")}>
                <Link to="/activatetv">
                  <img src={activeimg} className="drop-img" alt="" />
                  &nbsp;Activate TV
                </Link>
              </NavDropdown.Item>
              {/* <NavDropdown.Item eventKey="4.3"  onClick={() => handleNavigation("/preference")}   >
                <Link to="/preference">
                  <img src={preferimg} className="drop-img" alt="" />
                  &nbsp;My Preferences
                </Link>
              </NavDropdown.Item> */}

              <NavDropdown.Item eventKey="4.3"   onClick={handleLogout}   >
                <div
                  onClick={handleLogout}
                  style={{ cursor: "pointer" }}
                >
                  <img src={logoutnew} className="drop-img" alt="" />
                  &nbsp;Logout
                </div>
              </NavDropdown.Item>

              <NavDropdown.Item  onClick={handleLogoutAll}  >
                <div
                  onClick={handleLogoutAll}
                  style={{ cursor: "pointer" }}
                >
                  <img src={logoutnew} className="drop-img" alt="" />
                  &nbsp;Logout all devices
                </div>
              </NavDropdown.Item>

              <NavDropdown.Item onClick={() => handleNavigationforProfile("password")}   >
                <div
                  style={{ cursor: "pointer" }}
                >
                  {(accountData?.login_type === "email") && (
                    <>
                      <Link to="/profile?tab=password">
                        <GoKey style={{ color: "#f00", marginRight: "5px" }} />
                        Change Password
                      </Link>
                    </>
                  )}
                </div>
              </NavDropdown.Item>
            </NavDropdown>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li onClick={() =>  dispatch(setModalOpen())} style={{ cursor: "pointer" }}>
            Login
          </li>
        </>
      );
    }
  };

  const handleDownloadApp = () => {
    setClickedDownloadtab(!clickedDownloadtab)
  }
  const RenderMobileMenu = () => {

    const profileedit = "/images/profile-edit.png";
    const subscribe = "/images/subscri.png";
    const watchlist = "/images/watch-list.png";
    const mytransactions = "/images/my-transactions.png";
    const activetv = "/images/active-tv.png";
    const myprefer = "/images/my-prefer.png";
    const notitleimage = "/images/no-image-1.png";

    if (isUserLoggedInStatus === "true") {
      const profileimg = `${window?.location?.origin}/images/profile-img.png`;
// console.log("Nnsnsnsnns", profilePicture)
      return (
        <>
          <Accordion className="sub-accordion">
            <Accordion.Item eventKey="4">
              <Accordion.Header className="profile-header-style">
                <div className="profile-img-class">
                  {/* <img src={profilePicture || profileavatar || defaultavatar} alt="" /> */}
                  <img
  src={((profilePicture || profileavatar) == "null" ? defaultanime : (profilePicture || profileavatar)) || defaultavatar}
  alt=""
  // className="switchprofile"
/>
   
                </div>
                <div className="profile-name">
                  <p style={{ margin: "0px" }}>{(profilename ? profilename : accountData?.firstname) || ""}</p>
                </div>
              </Accordion.Header>
              {/* <NavDropdown.Item eventKey="4.1" onClick={handleSwitchProfile}>
  <Link to="/profiles" className="switch-profile-item">

<img src={profilePicture || profileavatar || defaultavatar} className="switchprofile" alt="" />

    
    <div className="profile-info">
      <p style={{ marginBottom: '3px' }}>{profilename || ""}</p>
      <p className="profile-action">Switch Profile <FaChevronRight /> </p>
    </div>
  </Link>
</NavDropdown.Item> */}
              <Accordion.Body>
                <ul className="mobile-item-style">
                <li onClick={handleSwitchProfile}>
                <Link to="/profiles" className="switch-profile-item">


<img
  src={((profilePicture || profileavatar) == "null" ? defaultanime : (profilePicture || profileavatar)) || defaultavatar}
  alt=""
  className="switchprofile"
/>
    
    <div className="profile-info">
      <p style={{ marginBottom: '3px' }}>{(profilename ? profilename : accountData?.firstname) || ""}</p>
      <p className="profile-action">Switch Profile <FaChevronRight style={{marginTop: "-2px"}} /> </p>
    </div>
  </Link>
                  </li>
  <li onClick={handleManageProfile} >
  <Link to="/profiles" onClick={() => setMenuOpen(false)}>
    <LiaUserEditSolid style={{ color: 'red', fontSize: '20px' }} />
    &nbsp;Manage Profile
  </Link>
                  </li>

                  <li onClick={() => handleNavigationforProfile("info")} >
                    <Link to="/profile?tab=info" onClick={() => setMenuOpen(false)}>
                      <img
                        src={profileedit}
                        alt=""
                        className="drop-img"
                      />
                      &nbsp;My Profile
                    </Link>
                  </li>

                    <li onClick={() => handleNavigationforProfile("subscription")}>
                      <Link to="/profile?tab=subscription" onClick={() => setMenuOpen(false)}>
                        <img
                          src={subscribe}
                          alt=""
                          className="drop-img"
                        />
                        &nbsp;Subscription
                      </Link>
                    </li>
                  <li>
                    <Link to="/watchlater" onClick={() => setMenuOpen(false)}>
                      <img
                        src={watchlist}
                        alt=""
                        className="drop-img"
                      />
                      &nbsp;My List
                    </Link>
                  </li>
                  <li onClick={() => handleNavigationforProfile("transactions")}>
                    <Link to="/profile?tab=subscription" onClick={() => setMenuOpen(false)}>
                      <img
                        src={mytransactions}
                        alt=""
                        className="drop-img"
                      />
                      &nbsp;My Transactions
                    </Link>
                  </li>
                  <li>
                    <Link to="/activatetv" onClick={() => setMenuOpen(false)}>
                      <img
                        src={activetv}
                        alt=""
                        className="drop-img"
                      />
                      &nbsp;Activate TV
                    </Link>
                  </li>

                  {/* <li>
                    <Link to="/preference" onClick={() => setMenuOpen(false)}>
                      <img
                        src={myprefer}
                        alt=""
                        className="drop-img"
                      />
                      &nbsp;My Preferences
                    </Link>
                  </li> */}

                  <li>
                    <Link onClick={handleLogout} style={{ cursor: "pointer" }}>
                      <img src={logoutnew} className="drop-img" alt="" />
                      &nbsp;Logout
                    </Link>
                  </li>

                  <li>
                    <Link onClick={handleLogoutAll}>
                      <img src={logoutnew} className="drop-img" alt="" />
                      &nbsp;Logout all devices
                    </Link>
                  </li>

                    {(getEmailId || accountData?.email_id) && (
                  <li onClick={() => handleNavigationforProfile("password")}>
                      <>
                      <Link to="/profile?tab=password" onClick={() => setMenuOpen(false)}>
                        <GoKey style={{ color: "#f00", marginRight: "5px" }} />
                        Change Password
                      </Link>
                      </>
                  </li>
                    )}

                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      );
    } else {
      const handleMenuHideLoginShow = () => {
        setMenuOpen(false);
        dispatch(setModalOpen())
      };

      return (
        <>
          <Link
            className="mobile-buton-login-class"
            onClick={handleMenuHideLoginShow}
            style={{ cursor: "pointer" }}
          >
              Login
          </Link>
        </>
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      setIsSticky(scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

const location = useLocation()
  // console.log("accountDatass", accountData)
 

  return (
    <div className="mobile-flex">
      <div className="left">
        {[false].map((expand) => (
          <Navbar
            key={expand}
            expand="lg"
            className={`chanel-categories${isSticky ? " stickynav" : ""}`}
          >


              
            <Container fluid className="pading-left-right-class d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <Navbar.Toggle onClick={toggleMenu} className="button-styling" />
                <Link to="/home" className="mobile-logo">
                  <img src={newlogo} width="60" alt="" />
                </Link>
              </div>

              <div className="d-flex align-items-center">
                <div className="mobilesubscribe">
{(redeemData.order_id ||  accountData.isUserSubscribed === true) ? "" :  <li>
                      <Nav.Link
                      onClick={() => {
                        //  handleNavigationforProfile("subscription");
                         if (isUserLoggedInStatus === "true") {
                            handleNavigationforProfile("subscription");
                          } else {
                            navigate('/subscription-plans');
                          }
                          setMenuOpen(false)
                        }} 

                        // as={Link}
                        style={{ padding: "0px 18px" }}
                        // to="/subscription"
                        className="button-color w-100 mb-3"
                      >
                        Subscribe
                      </Nav.Link>
                    </li>}
                </div>

                <div className="search-icon mobile-search">
                  <SearchFilter />
                </div>
              </div>

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                placement="start"
                className="MobleSideNav over-auto"
                restoreFocus={false}
                show={menuOpen}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton style={{ color: "#fff" }}>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <ul>
                      <li>
                        <img style={{width: '80px', height: "32px"}} src={newlogo} alt="" />
                      </li>
                    </ul>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <div className="mobile-login-regster">
                  <Row>
                    <Col sm={12}>
                      <Row>
                        <Col lg={12} style={{ padding: "0px" }}>
                          {RenderMobileMenu()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                        {(redeemData.order_id ||  accountData.isUserSubscribed === true) ? "" :  <li>
                          <Nav.Link
                           onClick={() => {
                            //  handleNavigationforProfile("subscription");
                             if (isUserLoggedInStatus === "true") {
                                handleNavigationforProfile("subscription");
                              } else {
                                navigate('/subscription-plans');
                              }
                              setMenuOpen(false)
                            }}
                            // as={Link}
                            // onClick={() => setMenuOpen(false)}
                            // to="/subscription"
                            className="button-color w-100 mb-3 mobile-subscribe"
                          >
                            Subscribe
                          </Nav.Link>
                        </li>}
                    </Col>
                    <Col sm={12}></Col>
                  </Row>

                </div>
                <Offcanvas.Body>
                  <Nav className="me-xl-auto me-lg-auto desktop-menu nav-list-mar">
                    <div className="header-logo">
                      <Link to="/home">
                        <img src={newlogo} alt="etv win logo" />
                      </Link>
                    </div>

                    {renderNavigationLinks()}
                  </Nav>

                  <ul className="li-links">
                    <li>
                      <div className="links" style={{ cursor: "pointer" }}>
                        <SearchFilter />
                      </div>
                    </li>
                    {RenderProfileMenu()}
                      {(redeemData.order_id ||  accountData.isUserSubscribed === true) ? "" : <li>
                        <Nav.Link
 onClick={() => {
  //  handleNavigationforProfile("subscription");
   if (isUserLoggedInStatus === "true") {
      handleNavigationforProfile("subscription");
    } else {
      navigate('/subscription-plans');
    }
  }}                          className="button-color"
                        >
                          Subscribe
                        </Nav.Link>
                      </li>}
                  </ul>

                  <div className="MenudownloadApp">
                      <div className="title">download app</div>
                        <div className="downloadLinks">
                          <a href="https://apps.apple.com/us/app/etv-win/id1245077673" target="_blank" rel="noreferrer">
                            <FaApple />
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.etvwin.mobile&pli=1" target="_blank" rel="noreferrer">
                            <FaGooglePlay />
                          </a>
                      </div>
                  </div>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
              
            </Container>
          </Navbar>
        ))}
      </div>

      <LoginScreen
        showLoginOTPPopup={showLoginOTPPopup}
        handleLoginOtpPopupClose={handleLoginOtpPopupClose}
        setShowLoginOTPPopup={setShowLoginOTPPopup}
      />

      <Register handleLogin={handleLogin} />
      <RegisterByMobile
        showOTPPopup={showOTPPopup}
        handleOtpPopupClose={handleOtpPopupClose}
        setShowOTPPopup={setShowOTPPopup}
        handleLogin={handleLogin}
      />

      <Logout />
      <LogoutAll />
     {isPopupVisible && <div className="fixed_pop_up_wrapper" id="install_pop_up">
      <div className="fixed_pop_up">
        <div className="pop_up_logo">
          <a href="/">
            <img className="" alt="icon" src={logo25} />
          </a>
        </div>
        <div className="download_text">
          <p>ETV Win</p>
          <p>Download ETV win app for a</p>
          <p>better experience.</p>
        </div>
        <div className="install_buttons">
          <button className="install" onClick={handledownload} >
            Download
          </button>
          <button className="no_install" onClick={closePopup}>
            X
          </button>
        </div>
      </div>
    </div>}
    </div>
  );
};

export default Header;
