import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import axios from "axios";
import "./manageprofiles.css";
import { auth_token, BASE_URL } from "../../services/config";
import { FaPencil } from "react-icons/fa6";

const ManageProfiles = () => {
  const { sessionId } = useSelector((state) => state.auth);
  const navigate = useNavigate(); // Initialize navigate
  const newlogo = "/images/etv-logo-new.png";
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const getaccessToken = useSelector(
    (state) => state.accesstoken_data.accessTokenData
  );

  const effectRan = useRef(false);
  const multiprofileurl = `${BASE_URL}/users/${sessionID}/profile_list?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
  const [multiProfiledata, setMultiProfiledata] = useState([]);

  useEffect(() => {
    if (effectRan.current === false) {
      MultiProfileData();
      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const MultiProfileData = async () => {
    try {
      const response = await axios.get(multiprofileurl);
      const multiprofiledata = response?.data?.profiles;
      if (response?.status === 200) {
        setMultiProfiledata(multiprofiledata);
      }
    } catch (error) {
      console.error("Error fetching data:", error?.message);
    }
  };

  // Navigate to EditProfile on image click
  const handleProfileClick = (profile) => {
    navigate(`/edit-profile/${profile.profile_id}`, { state: { profile } });
  };

  return (
    <div className="main-page-multi">
      <div className="profile-selection">
        <header className="header">
          <img src={newlogo} alt="Logo" className="logo" />
        </header>
        <h1 className="title">Edit profile</h1>
        <p className="title-two">You can create up to 5 different profiles</p>
        <div className="profiles">
          {multiProfiledata.map((profile) => (
            <div
              className="profile-card"
              key={profile.profile_id}
              onClick={() => handleProfileClick(profile)}
            >
              <div className="profile-avatar">
                <img
                  src={profile.profile_picture}
                  alt={profile.firstname}
                  className="profile-icon"
                />
                <button className="edit-icon">
                  <FaPencil />
                </button>
              </div>
              <p className="profile-name">{profile.firstname}</p>
            </div>
          ))}
          <div
  className="profile-card add-profille"
  onClick={() => navigate('/updateprofile')}
>
  <div className="profile-icon">+</div>
  <p className="profile-name">Add Profile</p>
</div>

        </div>
        <div className="footer-multi">
          <button className="profiles-management">Done</button>
        </div>
      </div>
    </div>
  );
};

export default ManageProfiles;
