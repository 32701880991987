import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./profileSelector.css";
import { auth_token, BASE_URL } from "../../services/config";
import { FaPencil } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  setManageProfileClicked,
  setProfileName,
  setProfilePicture,
  setSelectedProfile,
  setSelectedProfileId,
} from "../../Redux/Slice/multiprofileSlice";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

const ProfileSelector = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultanime = "/images/defaultanime.png";

  const { manageProfileClicked } = useSelector((state) => state.manage_profile);
  const profileIdFromStorage = localStorage.getItem("selectedProfileId");
  const { sessionId } = useSelector((state) => state.auth);
  const getAccessToken = useSelector(
    (state) => state.accesstoken_data.accessTokenData
  );

  const sessionID =
    localStorage.getItem("session_id") || sessionId || "";
  const [loading, setLoading] = useState(false);
  const [updateProfiles, setUpdateProfiles] = useState(false);
  const [deleteProfiles, setDeleteProfiles] = useState(false);
  const [isEditMode, setIsEditMode] = useState(manageProfileClicked || false);
  const [multiProfileData, setMultiProfileData] = useState([]);
  const [selectedProfileForEdit, setSelectedProfileForEdit] = useState(null);

  const multiprofileURL = `${BASE_URL}/users/${sessionID}/profile_list?auth_token=${auth_token}&access_token=${getAccessToken?.access_token}`;

  useEffect(() => {
    fetchMultiProfileData();
  }, []);

  useEffect(() => {
    setIsEditMode(manageProfileClicked);
  }, [manageProfileClicked]);
  
  const { profilePicture, selectedProfile, profileName, selectedProfileId } = useSelector(
    (state) => state.manage_profile
  );


  const fetchMultiProfileData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(multiprofileURL);
      if (response?.status === 200) {
        setMultiProfileData(response.data?.profiles || []);
      }
    } catch (error) {
      console.error("Error fetching profiles:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProfiles = () => {
    setIsEditMode(!isEditMode);
    dispatch(setManageProfileClicked({ manageProfileClicked: !isEditMode }));
  };

  const handleProfileSwitch = async (profile) => {
    const defaultSelectionURL = `${BASE_URL}/users/${sessionID}/${profile?.profile_id}/default_selection?auth_token=${auth_token}&access_token=${getAccessToken?.access_token}`;

    try {
      const response = await axios.get(defaultSelectionURL);
      const selected = multiProfileData.find(
        (prof) => prof.profile_id === profile?.profile_id
      );

      if (selected) {
        dispatch(setProfilePicture({ profilePicture: selected.profile_picture }));
        dispatch(setSelectedProfile({ selectedProfile: profile?.profile_id }));
        dispatch(setProfileName({ profileName: selected.firstname }));
        dispatch(setSelectedProfileId({ selectedProfileId: profile?.profile_id }));

        localStorage.setItem("selectedProfileId", profile?.profile_id);
        localStorage.setItem("profilePicture", selected.profile_picture);
        localStorage.setItem("profileName", selected.firstname);
      }

      navigate("/");
    } catch (error) {
      console.error("Error switching profile:", error.message);
    }
  };

  const handleProfileClick = (profile) => {
    setSelectedProfileForEdit(profile);
    setUpdateProfiles(true)
  };

  const handleEditSingleProfile = (profile) => {
    navigate(`/edit-profile/${profile.profile_id}`, { state: { profile } });
  };

  const handleDeleteSingleProfile = (profile) => {
    setDeleteProfiles(true)
    setUpdateProfiles(false)
  };

  const handleCancelDelete = (profile) => {
    setDeleteProfiles(false)
    setUpdateProfiles(false)
  };


  const handleProfileDelete = async (profile) => {
    try {
     const response = await axios.delete(
        `${BASE_URL}/users/${sessionID}/${profile.profile_id}/delete_profile`,
        {
          params: {
            auth_token: auth_token,
            access_token: getAccessToken?.access_token,
          },
        }
      );

      if(response?.status === 200){
        await fetchMultiProfileData();
        setDeleteProfiles(false);
        setUpdateProfiles(false)
        toast.success("profile deleted successfully");
        navigate("/profiles");
      }
    } catch (error) {
       toast.error(error?.response?.data?.error?.message);
       setDeleteProfiles(false);
       setUpdateProfiles(false)
      console.error("Delete Profile Error:", error);
    }
  };
  
  

  // console.log("multiProfileDatashs", multiProfileData)

  return (
    <div className="main-page-multi">
      <div className="profile-selection">
        <header className="headers">
          <img src="/images/etv-logo-new.png" alt="Logo" className="elogo" />
        </header>
        <h1 className="titleedit">{isEditMode ? "Edit Profiles" : "Who's watching?"}</h1>
        <p className="title-two">You can create up to 5 different profiles</p>

        <div className="profiles">
          {loading ? (
            <div className="loading-spinner">
              <Spinner animation="border" variant="danger" />
            </div>
          ) : (
            <div
            className="profiles"
            // style={{ flexWrap: multiProfileData.length === 5 ? "nowrap" : "wrap" }}
          >
            {multiProfileData.map((profile) => (
              <div key={profile.profile_id} className="text-center" style={{ position: "relative" }}>
                <div
                  className={`profile-card ${
                    profile.profile_id === (selectedProfileId || profileIdFromStorage)
                      ? "default-profile"
                      : ""
                  }`}
                  onClick={() =>
                    isEditMode
                      ? (multiProfileData.length < 3 ? handleEditSingleProfile(profile) : handleProfileClick(profile))
                      : handleProfileSwitch(profile)
                  }
                >
                  <img
                    src={profile.profile_picture ? profile.profile_picture : defaultanime}
                    alt={profile.firstname}
                    className="profile-icon"
                  />
                  {isEditMode && (
                    <button className="edit-icon">
                      <FaPencil />
                    </button>
                  )}
                </div>
                <p className="profile-name">{profile.firstname}</p>
              </div>
            ))}



{multiProfileData.length >= 3 && updateProfiles && (
  <Modal
    show={updateProfiles}
    onHide={() => setUpdateProfiles(false)}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    size="md"
    keyboard={false}
    className="profile-modal"
  >
    <Modal.Body className="multibody">
      <div className="text-center">
        <header className="headerss">
          <img src="/images/etv-logo-new.png" alt="Logo" className="elogos" />
        </header>
        <h3 className="modal-title mb-4">Choose an Action</h3>
        <div className="profile-optionz d-flex justify-content-center gap-4">
          <div onClick={() => handleEditSingleProfile(selectedProfileForEdit)} className="profile-optio d-flex align-items-center gap-2 shadow-sm p-3 text-center">
            <span className="option-icon text-primary">
              <FaPencil size={22}  />
            </span>
            <p className="option-text ">Edit Profile</p>
          </div>
          <div onClick={() => handleDeleteSingleProfile(selectedProfileForEdit)} className="profile-optio d-flex align-items-center gap-2 shadow-sm p-3 text-center">
            <span className="option-icon text-danger">
              <FaTrash size={22}  />
            </span>
            <p className="option-text ">Delete Profile</p>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)}




{deleteProfiles && (
  <Modal
    show={deleteProfiles}
    onHide={() => setDeleteProfiles(false)}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    size="md"
    keyboard={false}
    className="border-class"
  >
    <Modal.Body>
      <div className="card-deck row justify-content-md-center">
        <div className="col-lg-12 text-center">
          <div className="profile-options">
            {/* Edit Profile Button */}

            <header className="headerss">
          <img src="/images/etv-logo-new.png" alt="Logo" className="elogos" />
        </header>

          <h5 className="mb-4 deltext">Are you sure you want to delete?</h5>

            

            <Row className="justify-content-center">
  <Col
    lg={12}
    md={12}
    sm={12}
    xs={12}
    style={{ textAlign: "center" }}
  >
    <button
      className="login-button me-2"
      onClick={() => handleProfileDelete(selectedProfileForEdit)}
    >
      Ok
    </button>
    <button
      className="login-button ms-2"
      onClick={() => handleCancelDelete()}
    >
      Cancel
    </button>
  </Col>
</Row>
           
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
)}

            {multiProfileData.length < 5 && (
              <div style={{ position: "relative" }} className="text-center">
                <div className="profile-card add-profile" onClick={() => navigate("/updateprofile")}>
                  <div className="profile-icon">+</div>
                </div>
                <p className="profile-name">Add Profile</p>
              </div>
            )}
          </div>
          
          )}

        </div>

        <div className="footer-multi">
          <button className="profiles-manage" onClick={handleEditProfiles}>
            {isEditMode ? "Done" : "Edit Profiles"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSelector;
