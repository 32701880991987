import React, { useEffect, useState } from "react";
import "./newprofile.css";
import { auth_token, BASE_URL } from "../../services/config";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const NewProfile = () => {
    const { id } = useParams();
  
  const navigate = useNavigate()
  const location = useLocation();
  // const { profile } = location.state || {};

  const profileData = location.state?.profile || null;

  // console.log("profilesdssss", profileData)
  const [name, setName] = useState(profileData?.firstname || "");
  const [language, setLanguage] = useState("English");
  const [parentalControl, setParentalControl] = useState(false);
  const [error, setError] = useState("");

  const { sessionId } = useSelector((state) => state.auth);
  const newlogo = "/images/etv-logo-new.png";
  let sessionID = localStorage.getItem("session_id")
    ? localStorage.getItem("session_id")
    : sessionId;
  const getaccessToken = useSelector(
    (state) => state.accesstoken_data.accessTokenData
  );

  const handleNameChange = (e) => {
    setName(e.target.value);
    setError(""); 
  };


  useEffect(() => {
    if (!profileData) {
      fetchProfileData();
    } else {
      const storedState = localStorage.getItem("parentalControl");
      if (storedState !== null) {
        setParentalControl(JSON.parse(storedState));
      } else {
        setParentalControl(profileData.child === "true");
      }
    }
  }, []);
  
  const fetchProfileData = async () => {
    const profileUrl = `${BASE_URL}/users/${sessionID}/profile_list?auth_token=${auth_token}&access_token=${getaccessToken?.access_token}`;
  
    try {
      const response = await axios.get(profileUrl);
  
      if (response?.status === 200) {
        const profiles = response?.data?.profiles;
        // console.log("snnsnsn", profiles)
  
        const fetchedProfile = profiles.find((profile) => profile.profile_id === id);
  
        // console.log("SNnsnsnsn", fetchedProfile.child)
        if (fetchedProfile) {
          setName(fetchedProfile.firstname);
          setParentalControl(fetchedProfile.child === "true");
        } else {
          console.error("Profile not found for the given ID:", id);
        }
      }
    } catch (error) {
      console.error("Error fetching profile list:", error);
    }
  };

  const toggleParentalAccount = () => {
    const newState = !parentalControl;
    setParentalControl(newState);
    localStorage.setItem("parentalControl", JSON.stringify(newState)); // Store in localStorage
  };

  // Function to create a new profile
  const handleCreateProfile = async (event) => {
    event.preventDefault();
  
    if (name.trim() === "") {
      setError("Name is required");
      return;
    }
  
    const createProfileUrl = `${BASE_URL}/users/${sessionID}/create_profile`;
  
    try {
      const response = await axios.post(
        createProfileUrl,
        {
          auth_token,
          firstname: name,
          child: String(parentalControl), 
          access_token: getaccessToken?.access_token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response?.status === 200) {
        toast.success("Profile created successfully!");
        localStorage.setItem("parentalControl", JSON.stringify(parentalControl)); // Store in localStorage
        navigate("/profiles"); 
      }
    } catch (error) {
      console.error("Error creating profile:", error);
      toast.error("Failed to create profile.");
    }
  };

  const handleUpdateProfile = async (event) => {
    event.preventDefault();

    if (name.trim() === "") {
      setError("Name is required");
      return;
    }

    const updateProfileUrl = `${BASE_URL}/users/${sessionID}/update_profile`;

    try {
      const response = await axios.post(
        updateProfileUrl,
        {
          auth_token,
          firstname: name,
          child: String(parentalControl),
          access_token: getaccessToken?.access_token,
          profile_id: profileData?.profile_id, 
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.status === 200) {
        toast.success("Profile updated successfully!");
        navigate("/"); 
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    }
  };

  const handleSaveProfile = (event) => {
    if (profileData) {
      handleUpdateProfile(event);
    } else {
      handleCreateProfile(event); 
    }
  };


  return (
    <div className="new-profile-container">
      <header className="header">
        <img src={newlogo} alt="Logo" className="elogo" />
      </header>

      <div className="form-container">
      <h1 className="titleedit text-center">{profileData ? "Update Profile" : "Create New Profile"}</h1>
      <p className="title-two text-center">You can create up to 5 different profiles</p>
        <label className="kids-account-label">Profile Name*</label>
        <input
          type="text"
          className="input-field"
          placeholder="Enter the new name"
          value={name}
          onChange={handleNameChange}
        />
        {error && <p className="error-messag">{error}</p>}

        {profileData?.profile_type !== "admin" && 
        <div className="form-container">
      <div className="kids-account-container">
        <label className="kids-account-label">Parental Control</label>
        <div
className={`toggle-switch ${parentalControl ? "active" : ""}`}
onClick={toggleParentalAccount}
  >
    <div className="toggle-circle"></div>
    <span className="toggle-label on">ON</span>
    <span className="toggle-label off">OFF</span>
  </div>
      </div>
    </div>
        }
        <div className="footer-create">
          {/* <button className="profiles-management" onClick={handleCreateProfile}>
            Create
          </button> */}
             <button className="profiles-manage" onClick={handleSaveProfile}>
            {profileData ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewProfile;
